import React from 'react';
import PageTitle from '../../layouts/PageTitle';

const ViewNotification = (props) => {

    return (
        <>
            <PageTitle activeMenu={"View Notification"} motherMenu={"Notification"} />
        </>
    );
};

export default ViewNotification;
