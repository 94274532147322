import React from 'react';
import { Link } from "react-router-dom";

const Error405 = () => {
    return (
        <div className="fix-wrapper">
            <div className="container">
                {" "}
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="form-input-content text-center error-page">
                            <h1 className="error-text fw-bold">405</h1>
                            <h4>
                                <i className="fa fa-thumbs-down text-danger" />{" "}
                                Method Not Allowed
                            </h4>
                            <p>
                                The server has received your request, but the resource you are requesting doesn't support the request method
                            </p>
                            <div>
                                <Link className="btn btn-primary" to="/dashboard">
                                    Back to Home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error405;
