import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import DataTableSettings from '../../../helpers/DataTableSettings';
import PageTitle from '../../layouts/PageTitle';
import CommonLoader from '../../components/loader/CommonLoader';
import { API_STUDENT_LIST_XI } from '../../../config/Api';

const sessionOptions = [
    { value: '', label: 'Select Session' },
    { value: '1', label: '2022' },
    { value: '2', label: '2023' },
    { value: '3', label: '2024' }
];

const classOptions = [
    { value: '', label: 'Select Class' },
    { value: '1', label: 'XI' },
    { value: '2', label: 'XII' },
];

const ListOfStudent = (props) => {
    const [students, setStudents] = useState([]);
    const [searchValues, setSearchValues] = useState({
        sessionYear: '',
        classOp: ''
    });
    const [searchErrors, setSearchErrors] = useState({});
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [filterText, setFilterText] = useState("");

    const searchParam = [
        'student_fname',
        'student_mname',
        'student_lname',
        'student_session_yr',
    ];

    useEffect(() => {

    }, []);

    const handleChange = (e, field) => {
        if (field === 'sessionYear') {
            setSearchValues({ ...searchValues, sessionYear: e.value });
        }
        if (field === 'classOp') {
            setSearchValues({ ...searchValues, classOp: e.value });
        }
        setSearchErrors({ ...searchErrors, [field]: '' });
    };

    const validateSearch = () => {
        const {
            sessionYear,
            classOp
        } = searchValues;

        const errors = {};
        let isValid = true;

        if (!sessionYear) {
            isValid = false;
            errors.sessionYear = "Session Year is Required";
        }
        if (!classOp) {
            isValid = false;
            errors.classOp = "Class is Required";
        }

        setSearchErrors(errors);
        return isValid;
    };

    const getStudentData = () => {
        if (!validateSearch()) {
            return;
        }

        props.callRequest("GET", API_STUDENT_LIST_XI, true, null)
            .then(({ data }) => {
                setStudents(data.studentList);
                setLoadingIndicator(false);
            }).catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            name: <h5>Name</h5>,
            selector: row => row.student_fname,
            sortable: true,
            reorder: true,
            cell: (row) => (
                <>
                    {
                        `${row.student_fname} ${row.student_mname ? row.student_mname : ''} ${row.student_lname}`
                    }
                </>
            )
        },
        {
            name: <h5>Academic Session</h5>,
            selector: row => row.student_session_yr,
            sortable: true,
            reorder: true,
        },
        {
            name: <h5>VTC</h5>,
            selector: row => row.student_inst.vtc_name,
            sortable: true,
            reorder: true,
            cell: (row) => (
                <>
                    {
                        `${row.student_inst.vtc_name}`
                    }
                </>
            )
        },
        {
            name: <h5>District</h5>,
            selector: row => row.student_district.district_name,
            sortable: true,
            reorder: true,
        },
        {
            name: <h5>State</h5>,
            selector: row => row.student_state.state_name,
            sortable: true,
            reorder: true,
        },
        {
            name: <h5>Action</h5>,
            selector: row => row.action,
            sortable: false,
            cell: (row) => (
                <div>
                    <Link to={"#"} className="btn btn-xs sharp btn-primary me-1"><i className="fa fa-pencil" /></Link>
                    <Link to={"#"} className="btn btn-xs sharp btn-danger"><i className="fa fa-trash" /></Link>
                </div>
            )
        },
    ];

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search..."
                                className="me-2 rounded-pill"
                                aria-label="Search"
                                onChange={(e) => setFilterText(e.target.value)}
                            />
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }, []);

    return (
        <>
            <PageTitle activeMenu={"List Of Students"} motherMenu={"Students"} />
            <Row>
                <Col lg={12}>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="session">Academic Session(Year)</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={sessionOptions[searchValues.sessionYear]}
                                            options={sessionOptions}
                                            onChange={(e) => handleChange(e, 'sessionYear')}
                                        />
                                        {
                                            searchErrors.sessionYear && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.sessionYear}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="paper">Class</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={classOptions[searchValues.classOp]}
                                            options={classOptions}
                                            onChange={(e) => handleChange(e, 'classOp')}
                                        />
                                        {
                                            searchErrors.classOp && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.classOp}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <button className="btn btn-primary me-1"
                                    onClick={() => getStudentData()}
                                >
                                    Get Student Data
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={students && students.length > 0 ? columns : []}
                                    data={DataTableSettings.filterItems(
                                        students,
                                        searchParam,
                                        filterText
                                    )}
                                    direction="auto"
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={DataTableSettings.paginationPerPage}
                                    paginationRowsPerPageOptions={
                                        DataTableSettings.paginationRowsPerPageOptions
                                    }
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    persistTableHead
                                    progressPending={loadingIndicator}
                                    progressComponent={
                                        <CommonLoader
                                            loadingIndicator={loadingIndicator}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default ListOfStudent;