import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeContext from "./context/ThemeContext";
import { store } from './redux/store';
import axios from 'axios';
import { API_IS_MAINTENANCE } from './config/Api';

const root = ReactDOM.createRoot(document.getElementById('root'));

// axios.get(API_IS_MAINTENANCE).then(({ data }) => {
//   root.render(
//     <Provider store={store}>
//       <BrowserRouter basename='/'>
//         <ThemeContext>
//           <App under_maintenance={data.under_maintenance} message={data.message} />
//         </ThemeContext>
//       </BrowserRouter>
//     </Provider>
//   );
// });

root.render(
  <Provider store={store}>
    <BrowserRouter basename='/'>
      <ThemeContext>
        <App />
      </ThemeContext>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();