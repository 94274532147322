import React from 'react';
import AdminHome from './AdminHome';
import VTCHome from './VTCHome';

const Home = (props) => {
	const userRole = localStorage.getItem("userRole");

	return (
		<>
			{
				['SUPERADMIN'].includes(userRole) ? (
					<AdminHome {...props} />
				) : (
					<VTCHome {...props} />
				)
			}
		</>
	);
};
export default Home;