import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

export function NavMenuToggle() {
	let mainwrapper = document.querySelector("#main-wrapper");
	if (mainwrapper.classList.contains('menu-toggle')) {
		mainwrapper.classList.remove("menu-toggle");
	} else {
		mainwrapper.classList.add("menu-toggle");
	}
}

const NavHeader = (props) => {
	const [toggle, setToggle] = useState(false);
	const { openMenuToggle } = useContext(ThemeContext);
	const screenWidth = window.screen.width;

	const responsiveLogo = () => {
		if (screenWidth >= 1024 && !toggle) {
			return (<img src="/assets/images/logo.png" alt="logo" />);
		} else if (screenWidth < 1024 && !toggle) {
			return (<img src="/assets/images/logo_small.png" alt="logo" />);
		} else if (screenWidth < 1024 && toggle) {
			return (<img src="/assets/images/logo_small.png" alt="logo" />);
		} else if (screenWidth >= 1024 && toggle) {
			return (<img src="/assets/images/logo_small.png" alt="logo" />);
		} else {
			return (<img src="/assets/images/logo.png" alt="logo" />);
		}
	};

	return (
		<div className="nav-header">
			<Link to="/dashboard" className="brand-logo">
				{responsiveLogo()}
			</Link>

			<div
				className="nav-control"
				onClick={() => {
					setToggle(!toggle);
					openMenuToggle();
					NavMenuToggle();
				}}
			>
				<div className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHeader;
