import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('../../pages/authentication/Login')), 500);
    });
});

const AuthRoutes = (props) => {
    return (
        <Routes>
            <Route path='/' element={<Login {...props} />} />
            <Route exact path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AuthRoutes;
