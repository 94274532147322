import React from 'react';
import PageTitle from '../../layouts/PageTitle';

const MarksEntryClassXII = (props) => {
    return (
        <>
            <PageTitle activeMenu={"Class XII"} motherMenu={"Marks Entry"} />
            Class XII Marks Entry
        </>
    );
};

export default MarksEntryClassXII;
