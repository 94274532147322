import React, { useState } from 'react';
import Select from 'react-select';
import { DatePicker } from 'rsuite';
import PageTitle from '../../layouts/PageTitle';
import { useNavigate } from 'react-router-dom';
import { FaCalendar, FaClock } from 'react-icons/fa';
import { notificationSchema } from '../../../helpers/validation';

const displayOptions = [
    { value: '', label: 'Select Display' },
    { value: '1', label: 'Topbar' },
    { value: '2', label: 'Modal' },
    { value: '3', label: 'Maintenance' }
];

const initialValues = {
    notification_display: "",
    notification_message: "",
    notification_start_date: "",
    notification_start_time: "",
    notification_end_date: "",
    notification_end_time: "",
    notification_active: false,
};

const EditNotification = (props) => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const handleChange = (e, field) => {
        if (field === 'notification_display') {
            setFormValues({ ...formValues, notification_display: e.value });
        }
        if (field === 'notification_message') {
            setFormValues({ ...formValues, notification_message: e.target.value });
        }
        if (field === 'notification_start_date') {
            setFormValues({ ...formValues, notification_start_date: e });
        }
        if (field === 'notification_start_time') {
            setFormValues({ ...formValues, notification_start_time: e });
        }
        if (field === 'notification_end_date') {
            setFormValues({ ...formValues, notification_end_date: e });
        }
        if (field === 'notification_end_time') {
            setFormValues({ ...formValues, notification_end_time: e });
        }
        if (field === 'notification_active') {
            setFormValues({ ...formValues, notification_active: e.target.checked });
        }
        setErrors({ ...errors, [field]: '' });
    };

    const validateNotification = () => {
        const { notification_active, ...restData } = formValues;
        const { error } = notificationSchema.validate(restData, { abortEarly: false });
        if (!error) return true;

        const newErrors = error.details.reduce((acc, curr) => {
            if (curr.type === 'any.invalid') {
                return { ...acc, notification_end_date: curr.context.message };
            } else {
                return { ...acc, [curr.path[0]]: curr.message };
            }
        }, {});
        setErrors(newErrors);
        return false;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateNotification()) {
            return;
        }

        const putData = {
            ...formValues,
            notification_start_time: props.getTimeFromDateString(formValues.notification_start_time),
            notification_end_time: props.getTimeFromDateString(formValues.notification_end_time),
        };
    };

    return (
        <>
            <PageTitle activeMenu={"Edit Notification"} motherMenu={"Notification"} />
            <div className="row">
                <div className="col-xl-12 col-xxl-12 col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Notification Info</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="notification_display">Where to display</label>
                                            <Select
                                                isSearchable={false}
                                                defaultValue={displayOptions[formValues.notification_display]}
                                                options={displayOptions}
                                                onChange={(e) => handleChange(e, 'notification_display')}
                                            />
                                            {
                                                errors.notification_display && (
                                                    <div className="text-danger fs-12">
                                                        {errors.notification_display}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="notification_message">Message</label>
                                            <textarea
                                                id="notification_message"
                                                className="form-control"
                                                name='notification_message'
                                                rows="5"
                                                value={formValues.notification_message}
                                                onChange={(e) => handleChange(e, 'notification_message')}
                                            >
                                            </textarea>
                                            {
                                                errors.notification_message && (
                                                    <div className="text-danger fs-12">
                                                        {errors.notification_message}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="notification_start_date">Start Date</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="Start Date"
                                                    className="picker-suit"
                                                    oneTap
                                                    value={formValues.notification_start_date || null}
                                                    onChange={(e) => handleChange(e, 'notification_start_date')}
                                                />
                                                <div className="icon"><FaCalendar /></div>
                                            </div>
                                            {
                                                errors.notification_start_date && (
                                                    <div className="text-danger fs-12">
                                                        {errors.notification_start_date}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="notification_start_time">Start Time</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="Start Time"
                                                    className="picker-suit"
                                                    format="HH:mm"
                                                    showMeridian
                                                    value={formValues.notification_start_time || null}
                                                    onChange={(e) => handleChange(e, 'notification_start_time')}
                                                />
                                                <div className="icon"><FaClock /></div>
                                            </div>
                                            {
                                                errors.notification_start_time && (
                                                    <div className="text-danger fs-12">
                                                        {errors.notification_start_time}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="notification_end_date">End Date</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="End Date"
                                                    className="picker-suit"
                                                    oneTap
                                                    value={formValues.notification_end_date || null}
                                                    onChange={(e) => handleChange(e, 'notification_end_date')}
                                                />
                                                <div className="icon"><FaCalendar /></div>
                                            </div>
                                            {
                                                errors.notification_end_date && (
                                                    <div className="text-danger fs-12">
                                                        {errors.notification_end_date}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="end_time">End Time</label>
                                            <div className="input-hasicon mb-xl-0 mb-3">
                                                <DatePicker
                                                    placeholder="End Time"
                                                    className="picker-suit"
                                                    format="HH:mm"
                                                    showMeridian
                                                    value={formValues.notification_end_time || null}
                                                    onChange={(e) => handleChange(e, 'notification_end_time')}
                                                />
                                                <div className="icon"><FaClock /></div>
                                            </div>
                                            {
                                                errors.notification_end_time && (
                                                    <div className="text-danger fs-12">
                                                        {errors.notification_end_time}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <div className="form-check form-check-inline">
                                                <label className="form-label" htmlFor="notification_active">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        value={formValues.notification_active}
                                                        checked={formValues.notification_active}
                                                        onChange={(e) => handleChange(e, 'notification_active')}
                                                    />
                                                    Is Active
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        <button onClick={() => navigate('/all-notification')} className="btn btn-danger light">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditNotification;
