import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import { API_OTP_MARKS_ENTRY_AUTHORIZE_XI, API_OTP_MARKS_ENTRY_AUTHORIZE_VERIFY_XI } from '../../../../config/Api';
import { ThreeDots } from 'react-loader-spinner';

const OTPModal = (props) => {
    const userObj = JSON.parse(localStorage.getItem("user"));

    const {
        handleKeyPress,
        callRequest,
        fetchStudents,
        setShowOTPModal,
        otpUserInfo,
        setOTPUserInfo,
        modalType,
    } = props;

    const [formValues, setFormvalues] = useState({
        fullName: '',
        designation: '',
        mobile: '',
        otpAdminValue: '',
        otpUserValue: '',
        adminPhone: '',
        userPhone: '',
    });
    const [errors, setErrors] = useState({});
    const [showOTP, setShowOTP] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (modalType === 'MARK_APPROVE_XI') {
            setFormvalues({
                ...formValues,
                fullName: userObj?.u_fullname,
                designation: userObj?.u_designation,
                mobile: userObj?.u_phone,
                email: userObj?.u_email,
            });
        }
    }, [modalType]);

    const handleInputChange = (e, field) => {
        if (field === 'fullName') {
            setFormvalues(prevState => ({ ...prevState, fullName: e.target.value }));
        }
        if (field === 'designation') {
            setFormvalues(prevState => ({ ...prevState, designation: e.target.value }));
        }
        if (field === 'mobile') {
            setFormvalues(prevState => ({ ...prevState, mobile: e.target.value }));
        }
        if (field === 'otpAdminValue') {
            setFormvalues(prevState => ({ ...prevState, otpAdminValue: e.target.value }));
        }
        if (field === 'otpUserValue') {
            setFormvalues(prevState => ({ ...prevState, otpUserValue: e.target.value }));
        }
    };

    const validateInput = (type) => {
        const {
            fullName,
            designation,
            mobile,
            otpAdminValue,
            otpUserValue,
        } = formValues;

        const errors = {};
        let isValid = true;

        if (type === 'USER_VALIDATE') {
            if (!fullName) {
                isValid = false;
                errors.fullName = "Name is Required";
            }
            if (!designation) {
                isValid = false;
                errors.designation = "Designation is Required";
            }
            if (!mobile && modalType === 'MARK_ENTRY_XI') {
                isValid = false;
                errors.mobile = "Mobile is Required";
            }
        } else if (type === 'OTP_VALIDATE') {
            if (!otpAdminValue) {
                isValid = false;
                errors.otpAdminValue = "Admin OTP is Required";
            }
            if (!otpUserValue && modalType === 'MARK_ENTRY_XI') {
                isValid = false;
                errors.otpUserValue = "User OTP is Required";
            }
        }

        setErrors(errors);
        return isValid;
    };

    const handleUserSubmit = () => {
        if (!validateInput('USER_VALIDATE')) {
            return;
        }
        setShowLoader(true);
        setOTPUserInfo({
            ...otpUserInfo,
            full_name: formValues.fullName,
            designation: formValues.designation,
            phone_no: modalType === 'MARK_ENTRY_XI' ? formValues.mobile : null,
        });

        const payload = {
            full_name: formValues.fullName,
            designation: formValues.designation,
            phone_no: modalType === 'MARK_ENTRY_XI' ? formValues.mobile : null,
        };

        callRequest("POST", API_OTP_MARKS_ENTRY_AUTHORIZE_XI, true, payload).then(({ data }) => {
            setFormvalues(prevState => ({
                ...prevState,
                adminPhone: data.admin_phone,
                userPhone: data.user_phone,
            }));
            setShowOTP(true);
            setShowLoader(false);
            localStorage.setItem("otpExpireTime", data.otp_expire_time);

            Swal.fire({
                title: "OTP Send!",
                text: `${data.message}`,
                icon: "success"
            });
        }).catch((err) => {
            setShowLoader(false);
            toast.error(`${err?.response?.data?.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        });
    };

    const handleOTPSubmit = () => {
        if (!validateInput('OTP_VALIDATE')) {
            return;
        }
        setShowLoader(true);

        const payload = {
            user_phone: modalType === 'MARK_ENTRY_XI' ? formValues.mobile : null,
            admin_otp: formValues.otpAdminValue,
            user_otp: modalType === 'MARK_ENTRY_XI' ? formValues.otpUserValue : null,
        };

        callRequest("POST", API_OTP_MARKS_ENTRY_AUTHORIZE_VERIFY_XI, true, payload).then(({ data }) => {
            localStorage.setItem("isPhoneVerified", data.is_phone_verified);

            Swal.fire({
                title: "OTP Verified!",
                text: `${data.message}`,
                icon: "success"
            });
            setShowOTPModal(false);
            setShowLoader(false);
            modalType === 'MARK_ENTRY_XI' && fetchStudents('FETCH_STUDENT');
        }).catch((err) => {
            setShowLoader(false);
            if (err?.response?.data?.error) {
                setErrors(prevErrState => ({ ...prevErrState, otpVerifyErr: err?.response?.data?.message }));
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="form-group">
                        <label className="form-label" htmlFor="fullName">Name</label>
                        <input
                            type='text'
                            name='fullName'
                            placeholder='Enter Name'
                            className='form-control'
                            disabled={modalType !== 'MARK_ENTRY_XI'}
                            value={formValues.fullName ?? ''}
                            onChange={(e) => handleInputChange(e, 'fullName')}
                        />
                        <small className='text-danger fs-12'>{!formValues.fullName && errors.fullName}</small>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="designation">Designation</label>
                        <input
                            type='text'
                            name='designation'
                            placeholder='Enter Designation'
                            className='form-control'
                            disabled={modalType !== 'MARK_ENTRY_XI'}
                            value={formValues.designation ?? ''}
                            onChange={(e) => handleInputChange(e, 'designation')}
                        />
                        <small className='text-danger fs-12'>{!formValues.designation && errors.designation}</small>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="mobile">Mobile No.</label>
                        <input
                            type='text'
                            name='mobile'
                            placeholder='Enter Mobile no'
                            className='form-control'
                            maxLength={10}
                            disabled={modalType !== 'MARK_ENTRY_XI'}
                            value={formValues.mobile ?? ''}
                            onKeyDown={handleKeyPress}
                            onChange={(e) => handleInputChange(e, 'mobile')}
                        />
                        <small className='text-danger fs-12'>{!formValues.mobile && errors.mobile}</small>
                    </div>

                    {
                        modalType === 'MARK_APPROVE_XI' && (
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">Email.</label>
                                <input
                                    type='text'
                                    name='email'
                                    disabled={modalType !== 'MARK_ENTRY_XI'}
                                    className='form-control'
                                    value={formValues.email ?? ''}
                                />
                            </div>
                        )
                    }

                    {
                        !showOTP ? (
                            <button
                                type="submit"
                                className="btn btn-primary me-1"
                                style={{ width: "100%" }}
                                onClick={() => handleUserSubmit()}
                                disabled={showLoader}
                            >
                                {
                                    showLoader ? (
                                        <div className='d-flex justify-content-center'>
                                            <ThreeDots
                                                visible={showLoader}
                                                height="20"
                                                width="35"
                                                color="#FFFFFF"
                                                radius="9"
                                                ariaLabel="three-dots-loading"
                                            />
                                        </div>
                                    ) : (
                                        <span>Submit</span>
                                    )
                                }
                            </button>
                        ) : (
                            <>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="mobile">{`Admin OTP (Send to: ${formValues.adminPhone})`}</label>
                                    <input
                                        type='text'
                                        name='otpAdminValue'
                                        placeholder='Enter Admin OTP'
                                        className='form-control'
                                        autoComplete='off'
                                        value={formValues.otpAdminValue ?? ''}
                                        onChange={(e) => handleInputChange(e, 'otpAdminValue')}
                                    />
                                    <small className='text-danger fs-12'>
                                        {
                                            !formValues.otpAdminValue && errors.otpAdminValue
                                        }
                                    </small>
                                </div>
                                {
                                    modalType === 'MARK_ENTRY_XI' && (
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="mobile">{`User OTP (Send to: ${formValues.userPhone})`}</label>
                                            <input
                                                type='text'
                                                name='otpUserValue'
                                                placeholder='Enter User OTP'
                                                className='form-control'
                                                autoComplete='off'
                                                value={formValues.otpUserValue ?? ''}
                                                onChange={(e) => handleInputChange(e, 'otpUserValue')}
                                            />
                                            <small className='text-danger fs-12'>
                                                {
                                                    !formValues.otpUserValue && errors.otpUserValue
                                                }
                                            </small>
                                        </div>
                                    )
                                }

                                {
                                    errors.otpVerifyErr && (
                                        <div className="form-group">
                                            <small className='text-danger fs-12'>{errors.otpVerifyErr.toUpperCase()}</small>
                                        </div>
                                    )
                                }

                                <button
                                    type="submit"
                                    className="btn btn-primary me-1"
                                    style={{ width: "100%" }}
                                    onClick={() => handleOTPSubmit()}
                                    disabled={showLoader}
                                >
                                    {
                                        showLoader ? (
                                            <div className='d-flex justify-content-center'>
                                                <ThreeDots
                                                    visible={showLoader}
                                                    height="20"
                                                    width="35"
                                                    color="#FFFFFF"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                />
                                            </div>
                                        ) : (
                                            <span>Submit</span>
                                        )
                                    }
                                </button>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default OTPModal;
