import React, { useEffect, useState } from 'react';
import { FaCalendar } from 'react-icons/fa';
import Select from 'react-select';
import { DatePicker } from 'rsuite';
import Swal from 'sweetalert2';
import { API_UPDATE_CNFG_MARKS_SCHEDULE } from '../../../../config/Api';

const initialValues = {
    markScheduleClass: '',
    markType: '',
    scheduleDesc: '',
    startDate: '',
    endDate: '',
};

const ScheduleModal = (props) => {
    const {
        scheduleId,
        scheduleData,
        classOptions,
        marksTypeOptions,
        setShowScheduleModal,
        getScheduleData,
    } = props;

    const [formValues, setFormValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (scheduleId) {
            const scheduleObj = scheduleData.find((item) => item.id === scheduleId);

            if (scheduleObj) {
                setFormValues({
                    ...formValues,
                    markScheduleClass: scheduleObj.class_id,
                    markType: scheduleObj.type,
                    scheduleDesc: scheduleObj.schedule_desc,
                    startDate: new Date(scheduleObj.start_date),
                    endDate: new Date(scheduleObj.end_date),
                });
            }
        }
    }, [scheduleId]);

    const handleChange = (e, field) => {
        if (field === 'markScheduleClass') {
            setFormValues(prevState => ({ ...prevState, markScheduleClass: e ? e.value : null }));
        }
        if (field === 'markType') {
            setFormValues(prevState => ({ ...prevState, markType: e ? e.value : null, scheduleDesc: e ? e.value : null }));
        }
        if (field === 'startDate') {
            setFormValues(prevState => ({ ...prevState, startDate: e }));
        }
        if (field === 'endDate') {
            setFormValues(prevState => ({ ...prevState, endDate: e }));
        }
        setErrors({ ...errors, [field]: '' });
    };

    const validateSearch = () => {
        const {
            markScheduleClass,
            markType,
            startDate,
            endDate,
        } = formValues;

        const errors = {};
        let isValid = true;

        if (!markScheduleClass) {
            isValid = false;
            errors.markScheduleClass = "Class is Required";
        }
        if (!markType) {
            isValid = false;
            errors.markType = "Mark Type is Required";
        }
        if (!startDate) {
            isValid = false;
            errors.startDate = "Start Date is Required";
        }
        if (!endDate) {
            isValid = false;
            errors.endDate = "End Date is Required";
        }
        if (!props.compareAndEqualDate(startDate, endDate)) {
            isValid = false;
            errors.compareDateErr = "The End Date must be after the Start Date.";
        }

        setErrors(errors);
        return isValid;
    };

    const handleSave = () => {
        if (!validateSearch()) {
            return;
        }

        const payload = {
            class_id: formValues.markScheduleClass,
            type: formValues.markType,
            schedule_desc: formValues.scheduleDesc,
            start_date: props.convertDateStringToYMD(formValues.startDate),
            end_date: props.convertDateStringToYMD(formValues.endDate),
        };

        props.callRequest("POST", API_UPDATE_CNFG_MARKS_SCHEDULE, true, payload).then(({ data }) => {
            Swal.fire({
                title: "Submitted!",
                text: `${data.message}`,
                icon: "success"
            });
            setShowScheduleModal(false);
            getScheduleData('SAVE_SCHEDULE');
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <div className='row'>
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                    <label className="form-label" htmlFor="markScheduleClass">Schedule Class</label>
                    <Select
                        isSearchable={true}
                        isLoading={false}
                        isClearable={true}
                        value={
                            formValues.markScheduleClass ?
                                classOptions.find((item) => item.value === formValues.markScheduleClass)
                                : null
                        }
                        options={classOptions}
                        onChange={(e) => handleChange(e, 'markScheduleClass')}
                    />
                    {
                        !formValues.markScheduleClass && errors.markScheduleClass && (
                            <div className="text-danger fs-12">
                                {errors.markScheduleClass}
                            </div>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="paper">Marks Type</label>
                    <Select
                        isSearchable={false}
                        isLoading={false}
                        isClearable={true}
                        isDisabled={scheduleId}
                        value={
                            formValues.markType ?
                                marksTypeOptions.find((item) => item.value === formValues.markType)
                                : null
                        }
                        options={marksTypeOptions}
                        onChange={(e) => handleChange(e, 'markType')}
                    />
                    {
                        errors.markType && (
                            <div className="text-danger fs-12">
                                {errors.markType}
                            </div>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="startDate">Start Date</label>
                    <div className="input-hasicon mb-xl-0 mb-3" style={{ zIndex: "0" }}>
                        <DatePicker
                            placeholder="Start Date"
                            className="picker-suit"
                            oneTap
                            value={formValues.startDate || null}
                            onChange={(e) => handleChange(e, 'startDate')}
                        />
                        <div className="icon"><FaCalendar /></div>
                    </div>
                    {
                        errors.startDate && (
                            <div className="text-danger fs-12">
                                {errors.startDate}
                            </div>
                        )
                    }
                </div>
                <div className="form-group">
                    <label className="form-label" htmlFor="endDate">End Date</label>
                    <div className="input-hasicon mb-xl-0 mb-3" style={{ zIndex: "0" }}>
                        <DatePicker
                            placeholder="End Date"
                            className="picker-suit"
                            oneTap
                            value={formValues.endDate || null}
                            onChange={(e) => handleChange(e, 'endDate')}
                        />
                        <div className="icon"><FaCalendar /></div>
                    </div>
                    {
                        errors.endDate && (
                            <div className="text-danger fs-12">
                                {errors.endDate}
                            </div>
                        )
                    }
                    {
                        errors.compareDateErr && (
                            <div className="text-danger fs-12">
                                {errors.compareDateErr}
                            </div>
                        )
                    }
                </div>
                <button
                    className="btn btn-primary me-1"
                    onClick={() => handleSave()}
                >
                    {scheduleId ? 'Update' : 'Create'}
                </button>
                <button
                    className="btn btn-secondary me-1"
                    onClick={() => setShowScheduleModal(false)}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default ScheduleModal;
