import React, { Fragment } from "react";
import SideBar from "./SideBar";
import NavHeader from "./NavHeader";
import Header from "./Header";

const JobieNav = (props) => {

  return (
    <Fragment>
      <NavHeader {...props} />
      <Header
        {...props}
      />
      <SideBar {...props} />
    </Fragment>
  );
};

export default JobieNav;
