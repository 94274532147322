const { REACT_APP_API_DOMAIN, REACT_APP_API_WEB_DOMAIN } = process.env;

export const API_DOMAIN = REACT_APP_API_DOMAIN;
export const API_WEB_DOMAIN = REACT_APP_API_WEB_DOMAIN;

//Login
export const API_AUTHENTICATE = API_DOMAIN + "authenticate";

//Master List
export const API_NATIONALITY_LIST = API_DOMAIN + "master/nationality-list";
export const API_STATE_LIST = API_DOMAIN + "master/state-list";
export const API_DISTRICT_LIST = API_DOMAIN + "master/district-list";
export const API_SUBDIVISION_LIST = API_DOMAIN + "master/subdivision-list";
export const API_BLOCK_MUNICIPALITY_LIST = API_DOMAIN + "master/block-municipality-list";
export const API_POLICE_STATION_LIST = API_DOMAIN + "master/police-station-list";
export const API_NODAL_LIST = API_DOMAIN + "master/nodal-officer-list";
export const API_EDUCATION_BOARD_LIST = API_DOMAIN + "master/council-board-list";
export const API_EXAM_TYPE_LIST = API_DOMAIN + "master/exam-type-list";
export const API_DISCIPLINE_LIST = API_DOMAIN + "master/discipline-list";
export const API_RELIGION_LIST = API_DOMAIN + "master/religion-list";
export const API_CASTE_LIST = API_DOMAIN + "master/caste-list";
export const API_GENDER_LIST = API_DOMAIN + "master/get-gender-list";
export const API_RELATIONSHIP_LIST = API_DOMAIN + "master/guardian-relation";
export const API_VTC_LIST = API_DOMAIN + "master/get-all-vtc-list";
export const API_ALL_USERS = API_DOMAIN + "master/user-list";
export const API_SALUTATION_LIST = API_DOMAIN + "master/salutation-list";

//Attendance And Marks Entry Class XI
export const API_ACTIVE_SESSION = API_DOMAIN + "active-session";
export const API_ACTIVE_VTC_LIST = API_DOMAIN + "active-vtc-list";
export const API_VTC_WISE_DISCIPLINE = API_DOMAIN + "vtc-wise-dis";

export const API_VTC_DISCIPLINE_WISE_GROUP_XI = API_DOMAIN + "xi/vtc-dis-wise-group";
export const API_CLASS_ALL_PAPER_XI = API_DOMAIN + "xi/paper-list";
export const API_CLASS_SPECIAL_PAPER_XI = API_DOMAIN + "xi/special-paper-list";
export const API_CLASS_PAPER_ONE_XI = API_DOMAIN + "xi/class-paper1";
export const API_CLASS_OTHER_PAPER_XI = API_DOMAIN + "xi/class-otherpaper";

export const API_STUDENT_ATTENDANCE_LIST_XI = API_DOMAIN + "xi/student-attendance-list";
export const API_STUDENT_ATTENDANCE_UPDATE_XI = API_DOMAIN + "xi/student-attendance-update";
export const API_STUDENT_ATTENDANCE_LOCK_UNLOCK_XI = API_DOMAIN + 'xi/student-attendance-lock-unlock';
export const API_STUDENT_ATTENDANCE_FINAL_LOCK_XI = API_DOMAIN + "xi/student-attendance-final-lock";

export const API_STUDENT_MARKS_LIST_XI = API_DOMAIN + "xi/student-marks-list";
export const API_STUDENT_MARKS_APPROVE_LIST_XI = API_DOMAIN + "xi/student-marks-approve-list";
export const API_STUDENT_MARKS_UPDATE_XI = API_DOMAIN + "xi/student-marks-update";
export const API_STUDENT_MARKS_APPROVE_XI = API_DOMAIN + "xi/student-marks-approve";
export const API_STUDENT_MARKS_APPROVE_ALL_XI = API_DOMAIN + "xi/student-marks-approve-all";
export const API_STUDENT_MARKS_APPROVE_LOCK_UNLOCK_XI = API_DOMAIN + "xi/student-marks-approve-lock-unlock";
export const API_STUDENT_ATTENDANCE_PDF_XI = API_DOMAIN + "xi/attendence-pdf";
export const API_STUDENT_BLANK_ATTENDANCE_PDF_XI = API_DOMAIN + "xi/blank-attendence-pdf";
export const API_STUDENT_MARKS_FINAL_CHECK_XI = API_DOMAIN + "xi/student-marks-final-check";
export const API_STUDENT_MARKS_FINAL_LOCK_XI = API_DOMAIN + "xi/student-marks-final-lock";
export const API_STUDENT_MARK_TYPE_LIST_XI = API_DOMAIN + "xi/mark-type-list";

//OTP Api
export const API_OTP_MARKS_ENTRY_AUTHORIZE_XI = API_DOMAIN + "xi/marks-entry-authorize";
export const API_OTP_MARKS_ENTRY_AUTHORIZE_VERIFY_XI = API_DOMAIN + "xi/marks-entry-authorize-verify";

//Test OTP Api
export const API_SEND_OTP = API_DOMAIN + "send-otp";
export const API_VERIFY_OTP = API_DOMAIN + "verify-otp";

export const API_GET_CNFG_MARKS_SCHEDULE = API_DOMAIN + "master/get-cnfg-marks-schedule";
export const API_UPDATE_CNFG_MARKS_SCHEDULE = API_DOMAIN + "master/update-cnfg-marks-schedule";
export const API_CHECK_CNFG_MARKS_SCHEDULE = API_DOMAIN + "master/check-cnfg-marks-schedule";

//Students
export const API_STUDENT_LIST_XI = API_DOMAIN + "xi/list-students";

//Site Maintenance
export const API_IS_MAINTENANCE = API_DOMAIN + "is-maintenance";
