import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Modal, Button } from 'rsuite';
import DataTable from 'react-data-table-component';
import PageTitle from '../../layouts/PageTitle';
import Select from 'react-select';
import { FaEdit } from "react-icons/fa";
import CommonLoader from '../../components/loader/CommonLoader';
import ScheduleModal from './modals/ScheduleModal';
import { API_GET_CNFG_MARKS_SCHEDULE } from '../../../config/Api';

const marksTypeOptions = [
    { value: 'MARK_THEORY', label: 'Theory' },
    { value: 'MARK_PRACTICAL', label: 'Practical' },
    { value: 'MARK_PROJECT', label: 'Project' },
];

const classOptions = [
    { value: 1, label: 'Class XI' },
    { value: 2, label: 'Class XI' },
];

const initialValues = {
    markScheduleClass: '',
    markType: '',
};

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#6082b6',
            borderRight: '1px solid white',
            color: 'white',
        },
    },
};

const MarksEntrySchedule = (props) => {
    const [scheduleData, setScheduleData] = useState([]);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [searchValues, setSearchValues] = useState(initialValues);
    const [searchErrors, setSearchErrors] = useState({});
    const [scheduleId, setScheduleId] = useState(null);
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    const handleChange = (e, field) => {
        if (field === 'markScheduleClass') {
            setSearchValues(prevState => ({ ...prevState, markScheduleClass: e ? e.value : null }));
        }
        if (field === 'markType') {
            setSearchValues(prevState => ({ ...prevState, markType: e ? e.value : null }));
        }
        setSearchErrors({ ...searchErrors, [field]: '' });
    };

    const validateSearch = () => {
        const {
            markScheduleClass,
            markType,
        } = searchValues;

        const errors = {};
        let isValid = true;

        if (!markScheduleClass) {
            isValid = false;
            errors.markScheduleClass = "Class is Required";
        }
        if (!markType) {
            isValid = false;
            errors.markType = "Mark Type is Required";
        }

        setSearchErrors(errors);
        return isValid;
    };

    const getScheduleData = (status) => {
        if (!validateSearch()) {
            return;
        }
        status === 'FETCH_SCHEDULE' && setLoadingIndicator(true);

        const payload = {
            class_id: searchValues.markScheduleClass,
            type: searchValues.markType,
        };

        props.callRequest("POST", API_GET_CNFG_MARKS_SCHEDULE, true, payload).then(({ data }) => {
            setScheduleData(data.data);
            setLoadingIndicator(false);
        }).catch((err) => {
            err.response.data.error && setScheduleData([]);
            setLoadingIndicator(false);
        });
    };

    const handleCreateSchedule = () => {
        setShowScheduleModal(true);
        setScheduleId(null);
    };

    const handleEditSchedule = (sId) => {
        setScheduleId(sId);
        setShowScheduleModal(true);
    };

    const columns = [
        {
            name: <h5 style={{ color: 'white' }}>Sr No.</h5>,
            selector: row => row.id,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
        },
        {
            name: <h5 style={{ color: 'white' }}>Class</h5>,
            selector: row => row.class_id,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
            cell: (row) => (
                <div>
                    {
                        row.class_id === 1 ? 'XI' : 'XII'
                    }
                </div>
            )
        },
        {
            name: <h5 style={{ color: 'white' }}>Type</h5>,
            selector: row => row.type,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
        },
        {
            name: <h5 style={{ color: 'white' }}>Start Date</h5>,
            selector: row => row.start_date,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
        },
        {
            name: <h5 style={{ color: 'white' }}>End Date</h5>,
            selector: row => row.end_date,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
        },
        {
            name: <h5 style={{ color: 'white' }}>Action</h5>,
            selector: row => row.action,
            sortable: false,
            center: "true",
            cell: (row) => (
                <div>
                    <Button
                        appearance='primary'
                        color='green'
                        onClick={() => handleEditSchedule(row.id)}
                    >
                        <FaEdit title='Edit' size={'20px'} />
                    </Button>
                </div>
            )
        },
    ];

    return (
        <>
            <PageTitle activeMenu={"Marks Entry Schedule"} motherMenu={"Marks Entry"} />
            <Row>
                <Col lg={12}>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="markScheduleClass">Schedule Class</label>
                                        <Select
                                            isSearchable={true}
                                            isLoading={false}
                                            isClearable={true}
                                            value={
                                                searchValues.markScheduleClass ?
                                                    classOptions.find((item) => item.value === searchValues.markScheduleClass)
                                                    : null
                                            }
                                            options={classOptions}
                                            onChange={(e) => handleChange(e, 'markScheduleClass')}
                                        />
                                        {
                                            !searchValues.markScheduleClass && searchErrors.markScheduleClass && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.markScheduleClass}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="paper">Marks Type</label>
                                        <Select
                                            isSearchable={false}
                                            isLoading={false}
                                            isClearable={true}
                                            value={
                                                searchValues.markType ?
                                                    marksTypeOptions.find((item) => item.value === searchValues.markType)
                                                    : null
                                            }
                                            options={marksTypeOptions}
                                            onChange={(e) => handleChange(e, 'markType')}
                                        />
                                        {
                                            searchErrors.markType && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.markType}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <button className="btn btn-primary me-1" onClick={() => getScheduleData('FETCH_SCHEDULE')}>
                                    Get Schedule Data
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row >
            <Row>
                <Col>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                    <button className="btn btn-primary me-1" onClick={() => handleCreateSchedule()}>
                                        Create Schedule
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={scheduleData}
                                    customStyles={customStyles}
                                    direction="auto"
                                    highlightOnHover
                                    persistTableHead
                                    progressPending={loadingIndicator}
                                    progressComponent={
                                        <CommonLoader
                                            loadingIndicator={loadingIndicator}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>;
            {/* <!-- Modal --> */}
            <Modal
                backdrop='static'
                open={showScheduleModal}
                onClose={() => setShowScheduleModal(false)}
                overflow={false}
            >
                <Modal.Header>
                    <Modal.Title>{scheduleId ? 'Update Schedule' : 'Create Schedule'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleModal
                        {...props}
                        scheduleId={scheduleId}
                        scheduleData={scheduleData}
                        classOptions={classOptions}
                        marksTypeOptions={marksTypeOptions}
                        setShowScheduleModal={setShowScheduleModal}
                        getScheduleData={getScheduleData}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MarksEntrySchedule;