import { Col, Row } from 'react-bootstrap';

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//Import Components
import { BgCard } from '../../elements/CardDesign';

//Charts
const SurveyChart = loadable(() =>
    pMinDelay(import("../../elements/dashboard/SurveyChart"), 500)
);
const DonughtChart = loadable(() =>
    pMinDelay(import("../../elements/dashboard/DonughtChart"), 500)
);
const University = loadable(() =>
    pMinDelay(import("../../elements/dashboard/University"), 500)
);

const bgCarddBlog = [
    { title: "Total Students", number: '3180', icon: <i className="la la-users" />, percent: '80%', color: "primary" },
    { title: "New Students", number: '360', icon: <i className="la la-user" />, percent: '50%', color: "warning" },
    { title: "Total Course", number: '28', icon: <i className="la la-graduation-cap" />, percent: '60%', color: "secondary" },
    { title: "Fees Collection", number: '1290$', icon: <i className="la la-dollar" />, percent: '35%', color: "danger" },
];

const AdminHome = (props) => {

    return (
        <>
            <Row>
                {bgCarddBlog.map((item, index) => (
                    <Col xl={'3'} xxl={'3'} sm={'6'} key={index}>
                        <div className={`widget-stat card bg-${item.color}`}>
                            <div className="card-body">
                                <BgCard
                                    title={item.title}
                                    number={item.number}
                                    percent={item.percent}
                                    color={item.color}
                                    icon={item.icon} />
                            </div>
                        </div>
                    </Col>
                ))}
                {/* <Col xl={'6'} xxl={'6'} lg={'12'} sm={'12'}>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">University Survey</h3>
                        </div>
                        <div className="card-body">
                            <SurveyChart />
                        </div>
                    </div>
                </Col>
                <Col xl={"3"} xxl={"3"} sm={"6"}>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Donught Chart</h3>
                        </div>
                        <div className="card-body">
                            <DonughtChart />
                        </div>
                    </div>
                </Col>
                <Col xl={"3"} xxl={"3"} sm={"6"}>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">University Survey</h3>
                        </div>
                        <div className="card-body">
                            <University />
                        </div>
                    </div>
                </Col> */}
            </Row>
        </>
    );
};

export default AdminHome;
