import React from "react";

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

/// Image
import profile from "../../../assets/images/profile/education/pic1.jpg";
import Logout from "../nav/Logout";

const Header = (props) => {
  const userObj = JSON.parse(localStorage.getItem("user"));
  const user = {
    role: userObj?.role?.role_name,
    vtc_name: userObj?.u_vtc_name,
    name: userObj?.u_fullname,
    vtc_code: userObj?.u_vtc_code ?? null
  };

  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="header-title mt-2">
                  <h2 className="fw-bold">HS Vocational</h2>
                </div>
              </div>
              <div className="p-2 d-flex gap-3 align-items-center">
                <div>
                  <p style={{ color: "#6082b6", marginBottom: "1px" }}>Welcome, {user.name}</p>
                  <span style={{ fontSize: "12px" }}>{user.vtc_name}  {user.vtc_code && `[${user.vtc_code}]`}</span>
                </div>
                <ul className="navbar-nav header-right" style={{ cursor: "pointer" }}>
                  <Dropdown as="li" className="nav-item header-profile">
                    <Dropdown.Toggle to={"#"} className="nav-link i-false" as="div">
                      <img src={profile} width="20" alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end" className="mt-3 dropdown-menu dropdown-menu-right">
                      <Link to={"/profile"} className="dropdown-item ai-icon icon-bell-effect">
                        <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <span className="ms-2">Profile </span>
                      </Link>
                      <Logout />
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
