import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableSettings from '../../../../helpers/DataTableSettings';
import PageTitle from '../../../layouts/PageTitle';
import CommonLoader from '../../../components/loader/CommonLoader';
import { API_GENDER_LIST } from '../../../../config/Api';

const GenderList = (props) => {
    const [genders, setGenders] = useState([]);
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [page] = useState(1);
    const [newPerPage, setNewPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");

    const searchParam = [
        "gender_name",
    ];

    useEffect(() => {
        const fetchGenders = () => {
            props.callRequest("GET", API_GENDER_LIST, true, null)
                .then(({ data }) => {
                    setGenders(data.genderList);
                    setLoadingIndicator(false);
                }).catch((err) => {
                    console.log(err);
                });
        };
        fetchGenders();
    }, []);

    const columns = [
        {
            name: <h5>Gender</h5>,
            selector: row => row.gender_name,
            sortable: true,
            reorder: true,
        },
        {
            name: <h5>Action</h5>,
            selector: row => row.action,
            sortable: false,
            cell: (row) => (
                <div>
                    <Link to={"#"} className="btn btn-xs sharp btn-primary me-1"><i className="fa fa-pencil" /></Link>
                    <Link to={"#"} className="btn btn-xs sharp btn-danger"><i className="fa fa-trash" /></Link>
                </div>
            )
        },
    ];

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search..."
                                className="me-2 rounded-pill"
                                aria-label="Search"
                                onChange={(e) => setFilterText(e.target.value)}
                            />
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }, []);

    return (
        <>
            <PageTitle activeMenu={"All Genders"} motherMenu={"Master Lists"} />
            <Row>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">List of Genders</h4>
                            {/* <Link to={"#"} className="btn btn-primary">+ Add New</Link> */}
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={genders && genders.length > 0 ? columns : []}
                                    data={DataTableSettings.filterItems(
                                        genders,
                                        searchParam,
                                        filterText
                                    )}
                                    direction="auto"
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={DataTableSettings.paginationPerPage}
                                    paginationRowsPerPageOptions={
                                        DataTableSettings.paginationRowsPerPageOptions
                                    }
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    persistTableHead
                                    progressPending={loadingIndicator}
                                    progressComponent={
                                        <CommonLoader
                                            loadingIndicator={loadingIndicator}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row >
        </>
    );
};

export default GenderList;