import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Input, Modal, ButtonToolbar, RadioGroup, Radio } from 'rsuite';
import DataTable from 'react-data-table-component';
import PageTitle from '../../layouts/PageTitle';
import Select from 'react-select';
import Swal from 'sweetalert2';
import CommonLoader from '../../components/loader/CommonLoader';
import {
    API_ACTIVE_SESSION,
    API_ACTIVE_VTC_LIST,
    API_VTC_WISE_DISCIPLINE,
    API_VTC_DISCIPLINE_WISE_GROUP_XI,
    API_CLASS_ALL_PAPER_XI,
    API_STUDENT_MARKS_LIST_XI,
    API_STUDENT_MARKS_UPDATE_XI,
    API_CHECK_CNFG_MARKS_SCHEDULE,
    API_CLASS_SPECIAL_PAPER_XI,
    API_STUDENT_MARKS_FINAL_LOCK_XI,
    API_STUDENT_MARKS_FINAL_CHECK_XI,
    API_STUDENT_MARK_TYPE_LIST_XI,
} from '../../../config/Api';
import { ToastContainer, toast } from "react-toastify";
import OTPModal from './modals/OTPModal';

const marksTypeArr = ['THEORY', 'PRACTICAL', 'PROJECT'];

const initialValues = {
    examType: '',
    vtc: '',
    discipline: '',
    group: '',
    paper: '',
    examYear: '2024',
    sessionYear: '',
    markType: '',
    subjectTitle: '',
    disciplineName: '',
    groupName: '',
    marksTypeName: '',
    specialPaper: '',
};

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#6082b6',
            borderRight: '1px solid white',
            color: 'white',
        },
    },
};

const MARK_ENTRY_XI = true;

const MarksEntryClassXI = (props) => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    const isPhoneVerified = localStorage.getItem("isPhoneVerified");

    const [marksEntryclass11, setMarksEntryclass11] = useState([]);
    const [vtcOptions, setVtcOptions] = useState([]);
    const [disciplinesOptions, setDisciplinesOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [sessionOptions, setSessionOptions] = useState([]);
    const [examYearOptions, setExamYearOptions] = useState([]);
    const [paperOptions, setPaperOptions] = useState([]);
    const [specialPaperOptions, setSpecialPaperOptions] = useState([]);
    const [marksTypeOptions, setMarksTypeOptions] = useState([]);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [searchValues, setSearchValues] = useState(initialValues);
    const [searchErrors, setSearchErrors] = useState({});
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [marksEntry, setMarksEntry] = useState({
        error: false,
        isMarksEntry: true,
        message: null,
    });
    const [maxMarks, setMaxMarks] = useState({
        max_theory: 0,
        max_practical: 0,
        max_project: 0,
    });
    const [passMarks, setPassMarks] = useState({
        theory_pass: 0,
        practical_pass: 0,
        project_pass: 0,
    });
    const [otpUserInfo, setOTPUserInfo] = useState(null);

    useEffect(() => {
        setSearchValues(prevState => ({ ...prevState, vtc: userInfo?.u_vtc_code ? `${userInfo?.u_vtc_code}` : null }));
    }, [userInfo?.u_vtc_code]);

    useEffect(() => {
        fetchVTC();
    }, []);

    const fetchVTC = () => {
        const url = userInfo?.u_vtc_code ? API_ACTIVE_VTC_LIST + '/' + userInfo?.u_vtc_code : API_ACTIVE_VTC_LIST;

        props.callRequest("GET", url, true, null)
            .then(({ data }) => {
                let vtcArr = [];
                data?.vtcList?.map((item) => {
                    const tempObj = {
                        value: item.vtc_code,
                        label: `${item.vtc_name} (${item.vtc_code})`,
                        name: item.vtc_name,
                    };
                    vtcArr.push(tempObj);
                    return item;
                });
                setVtcOptions(vtcArr);
            }).catch((err) => {
                console.log(err);
                setGroupOptions([]);
            });
    };

    useEffect(() => {
        searchValues.vtc && fetchDiscipline(searchValues.vtc);
    }, [searchValues.vtc]);

    const fetchDiscipline = (vtc_code) => {
        const postData = { vtc_code: vtc_code };

        props.callRequest("POST", API_VTC_WISE_DISCIPLINE, true, postData).then(({ data }) => {
            let disciplineArr = [];
            data?.discipline?.map((item) => {
                const tempObj = {
                    value: item.discipline_id,
                    label: item.discipline_name,
                    code: item.discipline_code,
                    sh_code: item.discipline_sh_code,
                };
                disciplineArr.push(tempObj);
                return item;
            });
            setDisciplinesOptions(disciplineArr);
        }).catch((err) => {
            console.log(err);
            setGroupOptions([]);
        });
    };

    useEffect(() => {
        searchValues.examType && fetchActiveSession(searchValues.examType);
    }, [searchValues.examType]);

    const fetchActiveSession = (examType) => {
        const url = API_ACTIVE_SESSION + '/' + examType;

        props.callRequest("GET", url, true, null).then(({ data }) => {
            let activeSessionArr = [];
            data?.activeSessions?.map((item) => {
                const tempObj = {
                    value: item,
                    label: item,
                };
                activeSessionArr.push(tempObj);
                return item;
            });
            setSessionOptions(activeSessionArr);

            const examYear = {
                value: data?.examYear,
                label: data?.examYear,
            };
            setExamYearOptions([examYear]);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        searchValues.vtc && searchValues.discipline && fetchGroup();
    }, [searchValues.vtc, searchValues.discipline]);

    const fetchGroup = () => {
        const postData = { vtc_code: searchValues.vtc, discipline_id: searchValues.discipline };

        props.callRequest("POST", API_VTC_DISCIPLINE_WISE_GROUP_XI, true, postData).then(({ data }) => {
            let groupArr = [];
            data?.group?.map((item) => {
                const tempObj = {
                    value: item.group_id,
                    label: `${item.group_name} (${item.group_code})`,
                    code: item.group_code,
                };
                groupArr.push(tempObj);
                return item;
            });
            setGroupOptions(groupArr);
        }).catch((err) => {
            console.log(err);
            setGroupOptions([]);
        });
    };

    useEffect(() => {
        searchValues.examType &&
            searchValues.vtc &&
            searchValues.group &&
            searchValues.discipline &&
            searchValues.sessionYear &&
            fetchPaper();
    }, [
        searchValues.vtc,
        searchValues.group,
        searchValues.discipline,
        searchValues.examType,
        searchValues.sessionYear,
    ]);

    const fetchPaper = () => {
        const groupObj = groupOptions.find((item) => item.value === searchValues.group);

        const postData = {
            group_id: groupObj?.value,
            group_code: groupObj?.code,
            vtc_code: searchValues.vtc,
            discipline_id: searchValues.discipline,
            sessionYear: searchValues.sessionYear,
            examYear: searchValues.examYear,
            examType: searchValues.examType,
        };

        props.callRequest("POST", API_CLASS_ALL_PAPER_XI, true, postData).then(({ data }) => {
            let paperArr = [];
            data?.paper?.map((item) => {
                const tempObj = {
                    value: item.subject_code,
                    label: `${item.subject_name} (${item.subject_code})`,
                    type: item.subject_type,
                };
                paperArr.push(tempObj);
                return item;
            });
            setPaperOptions(paperArr);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        searchValues.paper &&
            searchValues.examType &&
            searchValues.vtc &&
            searchValues.group &&
            searchValues.discipline &&
            searchValues.sessionYear &&
            fetchSpecialPaper();
    }, [
        searchValues.paper,
        searchValues.vtc,
        searchValues.group,
        searchValues.discipline,
        searchValues.examType,
        searchValues.sessionYear,
    ]);

    const fetchSpecialPaper = () => {
        const groupObj = groupOptions.find((item) => item.value === searchValues.group);
        const paperObj = paperOptions.find((item) => item.value === searchValues.paper);

        const postData = {
            group_id: groupObj?.value,
            group_code: groupObj?.code,
            paper_code: searchValues.paper,
            paper_type: paperObj?.type,
            vtc_code: searchValues.vtc,
            discipline_id: searchValues.discipline,
            sessionYear: searchValues.sessionYear,
            examYear: searchValues.examYear,
            examType: searchValues.examType,
        };

        props.callRequest("POST", API_CLASS_SPECIAL_PAPER_XI, true, postData).then(({ data }) => {
            let paperArr = [];
            data?.paper?.map((item) => {
                const tempObj = {
                    value: item.special_paper_code,
                    label: item.special_paper_name,
                    type: item.special_paper_type,
                };
                paperArr.push(tempObj);
                return item;
            });
            setSpecialPaperOptions(paperArr);
        }).catch((err) => {
            console.log(err);
            setSpecialPaperOptions([]);
        });
    };

    useEffect(() => {
        searchValues.sessionYear &&
            searchValues.discipline &&
            searchValues.paper &&
            fetchMarkTypes();
    }, [
        searchValues.sessionYear,
        searchValues.discipline,
        searchValues.paper,
        searchValues.specialPaper,
    ]);

    const fetchMarkTypes = () => {
        const payload = {
            session_year: searchValues.sessionYear,
            discipline_id: searchValues.discipline,
            paper_code: searchValues.paper,
            special_paper_code: searchValues.specialPaper ?? null
        };

        props.callRequest("POST", API_STUDENT_MARK_TYPE_LIST_XI, true, payload)
            .then(({ data }) => {
                let markArr = [];
                data?.mark_list?.map((item) => {
                    const tempObj = {
                        value: item.type_value,
                        label: item.type_name,
                    };
                    markArr.push(tempObj);
                    return item;
                });
                setMarksTypeOptions(markArr);
            }).catch((err) => {
                console.log(err);
            });
    };

    const handleChange = (e, field) => {
        if (field === 'examType') {
            setSearchValues(prevState =>
                ({ ...prevState, examType: e, sessionYear: e === 'regular' ? '2023-24' : null, paper: null, markType: null, }));
            setPaperOptions([]);
            setMarksTypeOptions([]);
            setMarksEntryclass11([]);
        }
        if (field === 'vtc') {
            setSearchValues(prevState =>
            ({
                ...prevState,
                vtc: e ? e.value : null,
                discipline: null,
                group: null,
                paper: null,
                specialPaper: null,
                markType: null,
            }));
            setMarksEntryclass11([]);

            if (!e) {
                setDisciplinesOptions([]);
                setGroupOptions([]);
                setPaperOptions([]);
                setSpecialPaperOptions([]);
                setMarksTypeOptions([]);
                setMarksEntryclass11([]);
            }
        }
        if (field === 'discipline') {
            setSearchValues(prevState =>
            ({
                ...prevState,
                discipline: e ? e.value : null,
                disciplineName: !e && '',
                group: null, paper: null,
                specialPaper: null,
                markType: null,
            }));
            setMarksEntryclass11([]);

            if (!e) {
                setGroupOptions([]);
                setPaperOptions([]);
                setSpecialPaperOptions([]);
                setMarksTypeOptions([]);
                setMarksEntryclass11([]);
            }
        }
        if (field === 'group') {
            setSearchValues(prevState =>
            ({
                ...prevState,
                group: e ? e.value : null,
                groupName: !e && '',
                paper: null,
                specialPaper: null,
                markType: null,
            }));
            setMarksEntryclass11([]);

            if (!e) {
                setPaperOptions([]);
                setSpecialPaperOptions([]);
                setMarksTypeOptions([]);
                setMarksEntryclass11([]);
            }
        }
        if (field === 'paper') {
            setSearchValues(prevState =>
            ({
                ...prevState,
                paper: e ? e.value : null,
                specialPaper: null,
                subjectTitle: !e && '',
                markType: null,
            }));
            setMarksEntryclass11([]);

            if (!e) {
                setSpecialPaperOptions([]);
                setMarksTypeOptions([]);
                setMarksEntryclass11([]);
            }
            localStorage.removeItem("isPhoneVerified");
        }
        if (field === 'specialPaper') {
            setSearchValues(prevState => ({ ...prevState, specialPaper: e ? e.value : null, markType: null, }));
            setMarksEntryclass11([]);

            if (!e) {
                setMarksTypeOptions([]);
                setMarksEntryclass11([]);
            }
            localStorage.removeItem("isPhoneVerified");
        }
        if (field === 'sessionYear') {
            setSearchValues(prevState => ({ ...prevState, sessionYear: e ? e.value : null, markType: null, }));
            setMarksEntryclass11([]);

            if (!e) {
                setMarksTypeOptions([]);
            }
        }
        if (field === 'markType') {
            setSearchValues(prevState => ({ ...prevState, markType: e ? e.value : null, marksTypeName: !e && '' }));
            setMarksEntryclass11([]);
            setMarksEntry({
                ...marksEntry,
                error: false,
                isMarksEntry: true,
                message: null
            });
            localStorage.removeItem("isPhoneVerified");
        }

        setSearchErrors({ ...searchErrors, [field]: '' });
    };

    const validateSearch = () => {
        const {
            examType,
            discipline,
            group,
            paper,
            specialPaper,
            sessionYear,
            vtc,
            markType,
        } = searchValues;

        const errors = {};
        let isValid = true;

        if (!examType) {
            isValid = false;
            errors.examType = "Exam Type is Required";
        }
        if (!discipline) {
            isValid = false;
            errors.discipline = "Discipline is Required";
        }
        if (!group) {
            isValid = false;
            errors.group = "Group is Required";
        }
        if (!sessionYear) {
            isValid = false;
            errors.sessionYear = "Session Year is Required";
        }
        if (!paper) {
            isValid = false;
            errors.paper = "Paper is Required";
        }
        if (!specialPaper && specialPaperOptions.length > 0) {
            isValid = false;
            errors.specialPaper = "Special Paper is Required";
        }
        if (!vtc) {
            isValid = false;
            errors.vtc = "VTC is Required";
        }
        if (!markType) {
            isValid = false;
            errors.markType = "Mark Type is Required";
        }

        setSearchErrors(errors);
        return isValid;
    };

    useEffect(() => {
        searchValues.markType && validateMarksEntry(searchValues.markType);
    }, [searchValues.markType]);

    const validateMarksEntry = () => {
        const postData = {
            class_id: 1,
            type: searchValues.markType,
        };

        props.callRequest("POST", API_CHECK_CNFG_MARKS_SCHEDULE, true, postData).then((res) => {
            setMarksEntry({
                ...marksEntry,
                error: res.error,
                message: res.data.message
            });
        }).catch((err) => {
            if (err.response.data.error) {
                setMarksEntry({
                    ...marksEntry,
                    error: err.response.data.error,
                    message: err.response.data.message
                });
            }
        });
    };

    const getStudentData = (status) => {
        if (!validateSearch()) {
            return;
        }
        if (!marksEntry.error) {
            setMarksEntry({
                ...marksEntry,
                isMarksEntry: true,
            });
        } else {
            setMarksEntry({
                ...marksEntry,
                isMarksEntry: false,
            });
        }

        const paperObj = paperOptions.find((item) => item.value === searchValues.paper);
        const specialPaperObj = specialPaperOptions.find((item) => item.value === searchValues.specialPaper);

        const payload = {
            exam_type: searchValues.examType,
            vtc_code: searchValues.vtc,
            discipline_id: searchValues.discipline,
            group_id: searchValues.group,
            session_year: searchValues.sessionYear,
            exam_year: searchValues.examYear,
            paper_code: searchValues.paper,
            paper_type: paperObj?.type,
            special_paper_code: searchValues?.specialPaper || null,
            special_paper_type: specialPaperObj?.type || null,
            mark_type: searchValues.markType,
        };

        props.callRequest("POST", API_STUDENT_MARKS_FINAL_CHECK_XI, true, payload).then(({ data }) => {
            if (!data.attendence_final_submit) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${data.message}`,
                });
            } else if (data.is_locked) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `${data.message}`,
                });
            } else {
                if (!isPhoneVerified && !marksEntry.error) {
                    setShowOTPModal(true);
                } else {
                    !marksEntry.error && fetchStudents(status);
                }
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    const fetchStudents = (status) => {
        status === 'FETCH_STUDENT' && setLoadingIndicator(true);

        const paperObj = paperOptions.find((item) => item.value === searchValues.paper);
        const specialPaperObj = specialPaperOptions.find((item) => item.value === searchValues.specialPaper);

        const postData = {
            examType: searchValues.examType,
            vtc_code: searchValues.vtc,
            discipline_id: searchValues.discipline,
            group_id: searchValues.group,
            session_year: searchValues.sessionYear,
            mark_type: searchValues.markType,
            exam_year: searchValues.examYear,
            paper_code: searchValues.paper,
            special_paper_code: searchValues?.specialPaper || null,
            special_paper_type: specialPaperObj?.type || null,
            paper_type: paperObj?.type,
        };

        props.callRequest("POST", API_STUDENT_MARKS_LIST_XI, true, postData).then(({ data }) => {
            const tempArr = data?.students?.map((item) => {
                item.is_final = {
                    is_theory: !item.is_final ? false : item?.is_final?.is_theory,
                    is_practical: !item.is_final ? false : item?.is_final?.is_practical,
                    is_project: !item.is_final ? false : item?.is_final?.is_project,
                };
                item.theory_err = '';
                item.max_theory_err = '';
                item.practical_err = '';
                item.max_practical_err = '';
                item.project_err = '';
                item.max_project_err = '';

                return item;
            });
            setMarksEntryclass11(tempArr);
            setMaxMarks({
                ...maxMarks,
                max_theory: data?.max_marks?.max_theory,
                max_practical: data?.max_marks?.max_practical,
                max_project: data?.max_marks?.max_project,
            });
            setPassMarks({
                ...passMarks,
                theory_pass: data?.pass_marks?.theory_pass,
                practical_pass: data?.pass_marks?.practical_pass,
                project_pass: data?.pass_marks?.project_pass,
            });
            setLoadingIndicator(false);
        }).catch((err) => {
            console.log(err);
            setMarksEntryclass11([]);
            setLoadingIndicator(false);
        });
    };

    const handleInputChange = (reg_no, field, value) => {
        const updatedData = marksEntryclass11.map((item) => {
            if (item.reg_no === reg_no) {
                item[field] = value;
            }

            return item;
        });
        setMarksEntryclass11(updatedData);
    };

    const validateInput = (type) => {
        let isValid = true;

        const tempArr = marksEntryclass11.map((item) => {
            if (searchValues.markType === 'MARK_THEORY') {
                if (!item.theory) {
                    isValid = false;
                    item.theory_err = 'Required';
                } else if (item.theory && item.theory > item.max_theory) {
                    isValid = false;
                    item.max_theory_err = `Maximum is ${item.max_theory}`;
                }
            } else if (searchValues.markType === 'MARK_PRACTICAL') {
                if (!item.practical) {
                    isValid = false;
                    item.practical_err = 'Required';
                } else if (item.practical && item.practical > item.max_practical) {
                    isValid = false;
                    item.max_practical_err = `Maximum is ${item.max_practical}`;
                }
            } else if (searchValues.markType === 'MARK_PROJECT') {
                if (!item.project) {
                    isValid = false;
                    item.project_err = 'Required';
                } else if (item.project && item.project > item.max_project) {
                    isValid = false;
                    item.max_project_err = `Maximum is ${item.max_project}`;
                }
            }
            return item;
        });

        setMarksEntryclass11(tempArr);
        return isValid;
    };

    const handleSave = () => {
        if (!validateInput('SAVE')) {
            return;
        }

        const paperObj = paperOptions.find((item) => item.value === searchValues.paper);
        const specialPaperObj = specialPaperOptions.find((item) => item.value === searchValues.specialPaper);

        const updatedData = marksEntryclass11.map((mark) => {
            const {
                theory_err,
                max_theory_err,
                practical_err,
                max_practical_err,
                project_err,
                max_project_err,
                ...item
            } = mark;

            item.vtc_code = searchValues.vtc;
            item.discipline_id = searchValues.discipline;
            item.group_id = searchValues.group;
            item.session_year = searchValues.sessionYear;
            item.exam_year = searchValues.examYear;
            item.paper_code = searchValues.paper;
            item.special_paper_code = searchValues?.specialPaper || null;
            item.special_paper_type = specialPaperObj?.type || null;
            item.paper_type = paperObj?.type;
            item.mark_user_info = otpUserInfo;

            return item;
        });

        props.callRequest("POST", API_STUDENT_MARKS_UPDATE_XI, true, updatedData).then(({ data }) => {
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
            fetchStudents('MARKS_UPDATE');
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleFinalSubmit = () => {
        if (!validateInput('FINAL_SUBMIT')) {
            return;
        }

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            backdrop: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const paperObj = paperOptions.find((item) => item.value === searchValues.paper);
                const specialPaperObj = specialPaperOptions.find((item) => item.value === searchValues.specialPaper);

                const updatedData = marksEntryclass11.map((mark) => {
                    const {
                        theory_err,
                        max_theory_err,
                        practical_err,
                        max_practical_err,
                        project_err,
                        max_project_err,
                        ...item
                    } = mark;

                    item.vtc_code = searchValues.vtc;
                    item.discipline_id = searchValues.discipline;
                    item.group_id = searchValues.group;
                    item.session_year = searchValues.sessionYear;
                    item.exam_year = searchValues.examYear;
                    item.paper_code = searchValues.paper;
                    item.paper_type = paperObj?.type;
                    item.special_paper_code = searchValues?.specialPaper || null;
                    item.special_paper_type = specialPaperObj?.type || null;
                    item.mark_user_info = otpUserInfo;

                    if (searchValues.markType === 'MARK_THEORY') {
                        item.is_final.is_theory = true;
                    } else if (searchValues.markType === 'MARK_PRACTICAL') {
                        item.is_final.is_practical = true;
                    } else if (searchValues.markType === 'MARK_PROJECT') {
                        item.is_final.is_project = true;
                    }

                    return item;
                });

                props.callRequest("POST", API_STUDENT_MARKS_FINAL_LOCK_XI, true, updatedData).then(({ data }) => {
                    Swal.fire({
                        title: "Submitted!",
                        text: `${data.message}`,
                        icon: "success"
                    });
                    fetchStudents('FINAL_SUBMIT');
                }).catch((err) => {
                    console.log(err);
                });
            }
        });
    };

    useEffect(() => {
        const paperObj = searchValues.paper && paperOptions.find((item) => item.value === searchValues.paper);
        const disciplineObj = searchValues.discipline && disciplinesOptions.find((item) => item.value === searchValues.discipline);
        const groupObj = searchValues.group && groupOptions.find((item) => item.value === searchValues.group);
        const markTypeObj = searchValues.markType && marksTypeOptions.find((item) => item.value === searchValues.markType);

        if (paperObj && disciplineObj && groupObj && markTypeObj) {
            setSearchValues({
                ...searchValues,
                subjectTitle: paperObj.label,
                disciplineName: disciplineObj.label,
                groupName: groupObj.label,
                marksTypeName: markTypeObj.label,
            });
        }
    }, [
        searchValues.paper,
        searchValues.discipline,
        searchValues.group,
        searchValues.markType,
    ]);

    const columnsArr = [
        {
            name: <h5 style={{ color: 'white' }}>Regn No.</h5>,
            selector: row => row.reg_no,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6', paddingTop: '10px', paddingBottom: '10px' },
        },
        {
            name: <h5 style={{ color: 'white' }}>Student Name</h5>,
            selector: row => row.stu_name,
            sortable: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6', paddingTop: '10px', paddingBottom: '10px' },
        },
        {
            name: <h5 style={{ color: 'white' }}>{`Theory (Full Marks:${maxMarks.max_theory})`}</h5>,
            selector: row => row.theory,
            sortable: false,
            center: "true",
            style: { borderRight: '1px solid #6082b6', paddingTop: '10px', paddingBottom: '10px' },
            cell: (row) => (
                <div>
                    <Input
                        htmlSize={5}
                        maxLength={2}
                        style={{ width: 'auto', padding: '10px', border: '1px solid black', color: 'black' }}
                        name="theory"
                        value={row.theory || ""}
                        disabled={!row.is_present ? true : row.is_final.is_theory ? true : false}
                        onChange={(e) => handleInputChange(row.reg_no, 'theory', e)}
                        onKeyDown={props.handleKeyPress}
                    />
                    {
                        <div className="text-danger">
                            {
                                !row.theory ? row.theory_err :
                                    row.theory && row.theory > row.max_theory ?
                                        row.max_theory_err : ''
                            }
                        </div>
                    }
                </div>
            )
        },
        {
            name: <h5 style={{ color: 'white' }}>{`Practical (Full Marks:${maxMarks.max_practical})`}</h5>,
            selector: row => row.practical,
            sortable: false,
            center: "true",
            style: { borderRight: '1px solid #6082b6', paddingTop: '10px', paddingBottom: '10px' },
            cell: (row) => (
                <div>
                    <Input
                        htmlSize={5}
                        maxLength={2}
                        name="practical"
                        value={row.practical || ""}
                        disabled={!row.is_present ? true : row.is_final.is_practical ? true : false}
                        style={{ width: 'auto', padding: '10px', border: '1px solid black', color: 'black' }}
                        onChange={(e) => handleInputChange(row.reg_no, 'practical', e)}
                        onKeyDown={props.handleKeyPress}
                    />
                    {
                        <div className="text-danger">
                            {
                                !row.practical ?
                                    row.practical_err :
                                    row.practical && row.practical > row.max_practical ?
                                        row.max_practical_err : ''
                            }
                        </div>
                    }
                </div>
            )
        },
        {
            name: <h5 style={{ color: 'white' }}>{`Project (Full Marks:${maxMarks.max_project})`}</h5>,
            selector: row => row.project,
            sortable: false,
            center: "true",
            style: { borderRight: '1px solid #6082b6', paddingTop: '10px', paddingBottom: '10px' },
            cell: (row) => (
                <div>
                    <Input
                        htmlSize={5}
                        maxLength={2}
                        style={{ width: 'auto', padding: '10px', border: '1px solid black', color: 'black' }}
                        name="project"
                        value={row.project || ""}
                        disabled={!row.is_present ? true : row.is_final.is_project ? true : false}
                        onChange={(e) => handleInputChange(row.reg_no, 'project', e)}
                        onKeyDown={props.handleKeyPress}
                    />
                    {
                        <div className="text-danger">
                            {
                                !row.project ? row.project_err :
                                    row.project && row.project > row.max_project ?
                                        row.max_project_err : ''
                            }
                        </div>
                    }
                </div>
            )
        },
    ];

    const getMarksWiseColumns = (markType) => {
        if (markType === 'MARK_THEORY') {
            const colsArr = columnsArr.filter(column =>
                column.name.props.children === "Regn No." ||
                column.name.props.children === "Student Name" ||
                column.name.props.children === `Theory (Full Marks:${maxMarks.max_theory})`
            );

            return colsArr;
        } else if (markType === 'MARK_PRACTICAL') {
            const colsArr = columnsArr.filter(column =>
                column.name.props.children === "Regn No." ||
                column.name.props.children === "Student Name" ||
                column.name.props.children === `Practical (Full Marks:${maxMarks.max_practical})`
            );

            return colsArr;
        } else if (markType === 'MARK_PROJECT') {
            const colsArr = columnsArr.filter(column =>
                column.name.props.children === "Regn No." ||
                column.name.props.children === "Student Name" ||
                column.name.props.children === `Project (Full Marks:${maxMarks.max_project})`
            );

            return colsArr;
        }
    };

    const columns = marksEntryclass11.length > 0 ? getMarksWiseColumns(searchValues.markType) : [];

    return (
        <>
            <ToastContainer />
            <PageTitle activeMenu={"Marks Entry"} motherMenu={"Class XI"} />
            <Row>
                <Col lg={12}>
                    <div className='card shadow'>
                        <div className="card-body" style={{ fontSize: "14px" }}>
                            <Form.Group controlId="radioList">
                                <label className="form-label" htmlFor="exam_type">Exam Type</label>
                                <RadioGroup name="radioList"
                                    inline
                                    className="d-flex justify-content-between"
                                    style={{ width: '35%' }}
                                    onChange={(e) => handleChange(e, 'examType')}
                                >
                                    <Radio value="regular" checked={searchValues.examType === 'regular'}>Regular</Radio>
                                    <Radio value="casual" checked={searchValues.examType === 'casual'}>Continuing/Casual</Radio>
                                </RadioGroup>
                                {
                                    searchErrors.examType && (
                                        <div className="text-danger fs-12">
                                            {searchErrors.examType}
                                        </div>
                                    )
                                }
                            </Form.Group>
                        </div>
                    </div>
                </Col>
            </Row>

            {
                searchValues.examType && (
                    <Row>
                        <Col lg={12}>
                            <div className="card shadow">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="vtc">VTC</label>
                                                <Select
                                                    isSearchable={true}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    value={
                                                        searchValues.vtc ?
                                                            vtcOptions.find((item) => item.value === searchValues.vtc)
                                                            : null
                                                    }
                                                    options={vtcOptions}
                                                    onChange={(e) => handleChange(e, 'vtc')}
                                                />
                                                {
                                                    searchErrors.vtc && (
                                                        <div className="text-danger fs-12">
                                                            {searchErrors.vtc}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="disciplines">Disciplines</label>
                                                <Select
                                                    isSearchable={true}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    value={
                                                        searchValues.discipline ?
                                                            disciplinesOptions.find((item) => item.value === searchValues.discipline)
                                                            : null
                                                    }
                                                    options={disciplinesOptions}
                                                    onChange={(e) => handleChange(e, 'discipline')}
                                                />
                                                {
                                                    searchErrors.discipline && (
                                                        <div className="text-danger fs-12">
                                                            {searchErrors.discipline}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="groups">Groups</label>
                                                <Select
                                                    isSearchable={true}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    value={
                                                        searchValues.group ?
                                                            groupOptions.find((item) => item.value === searchValues.group)
                                                            : null
                                                    }
                                                    options={groupOptions}
                                                    onChange={(e) => handleChange(e, 'group')}
                                                />
                                                {
                                                    searchErrors.group && (
                                                        <div className="text-danger fs-12">
                                                            {searchErrors.group}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="session">Academic Session(Year)</label>
                                                <Select
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    isClearable={false}
                                                    isDisabled={false}
                                                    value={
                                                        searchValues.sessionYear ?
                                                            sessionOptions.find((item) => item.value === searchValues.sessionYear) :
                                                            null
                                                    }
                                                    options={sessionOptions}
                                                    onChange={(e) => handleChange(e, 'sessionYear')}
                                                />
                                                {
                                                    !searchValues.sessionYear && searchErrors.sessionYear && (
                                                        <div className="text-danger fs-12">
                                                            {searchErrors.sessionYear}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="exam_year">Exam Year</label>
                                                <Select
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    isClearable={false}
                                                    isDisabled={true}
                                                    value={
                                                        examYearOptions.length > 0 ?
                                                            examYearOptions.find((item) => item.value === searchValues.examYear) :
                                                            null
                                                    }
                                                    options={examYearOptions}
                                                />
                                            </div>
                                        </div>
                                        <div className={
                                            specialPaperOptions.length > 0 ?
                                                "col-lg-2 col-md-2 col-sm-2"
                                                : "col-lg-3 col-md-3 col-sm-3"
                                        }>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="paper">Subject</label>
                                                <Select
                                                    isSearchable={true}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    value={
                                                        searchValues.paper ?
                                                            paperOptions.find((item) => item.value === searchValues.paper)
                                                            : null
                                                    }
                                                    options={paperOptions}
                                                    onChange={(e) => handleChange(e, 'paper')}
                                                />
                                                {
                                                    searchErrors.paper && (
                                                        <div className="text-danger fs-12">
                                                            {searchErrors.paper}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            specialPaperOptions.length > 0 && (
                                                <div className="col-lg-2 col-md-2 col-sm-2">
                                                    <div className="form-group">
                                                        <label className="form-label" htmlFor="specialPaper">Paper</label>
                                                        <Select
                                                            isSearchable={true}
                                                            isLoading={false}
                                                            isClearable={true}
                                                            value={
                                                                searchValues.specialPaper ?
                                                                    specialPaperOptions.find((item) => item.value === searchValues.specialPaper)
                                                                    : null
                                                            }
                                                            options={specialPaperOptions}
                                                            onChange={(e) => handleChange(e, 'specialPaper')}
                                                        />
                                                        {
                                                            searchErrors.specialPaper && (
                                                                <div className="text-danger fs-12">
                                                                    {searchErrors.specialPaper}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className={
                                            specialPaperOptions.length > 0 ?
                                                "col-lg-2 col-md-2 col-sm-2"
                                                : "col-lg-3 col-md-3 col-sm-3"
                                        }>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="markType">Marks Type</label>
                                                <Select
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    value={
                                                        searchValues.markType ?
                                                            marksTypeOptions.find((item) => item.value === searchValues.markType)
                                                            : null
                                                    }
                                                    options={marksTypeOptions}
                                                    onChange={(e) => handleChange(e, 'markType')}
                                                />
                                                {
                                                    searchErrors.markType && (
                                                        <div className="text-danger fs-12">
                                                            {searchErrors.markType}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <button className="btn btn-primary me-1" onClick={() => getStudentData('FETCH_STUDENT')}>
                                            Get Student Data
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }

            {
                marksEntryclass11.length > 0 && (
                    <Row className='mb-4'>
                        <Col lg={12}>
                            <div className='w-100 p-3 rounded' style={{ backgroundColor: '#C1E1C1' }}>
                                <div className='w-100'>
                                    <div className='d-flex justify-content-between' style={{ color: '#097969', fontSize: '14px' }}>
                                        <div>
                                            <span>Subject:{" "}{searchValues.subjectTitle}</span>
                                        </div>
                                        <div>
                                            <span>Discipline:{" "}{searchValues.disciplineName}</span>
                                        </div>
                                        <div>
                                            <span>Group:{" "}{searchValues.groupName}</span>
                                        </div>
                                        <div>
                                            <span>Marks Type:{" "}{searchValues.marksTypeName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }

            <Row>
                <Col>
                    <div className="card shadow">
                        {
                            marksEntry.isMarksEntry ? (
                                <div className="card-body">
                                    {
                                        marksEntryclass11.length > 0 && (
                                            <ButtonToolbar className="mb-3 justify-content-end">
                                                <button className="btn btn-primary me-1" onClick={() => handleSave()}>
                                                    Save
                                                </button>
                                                <button
                                                    className={
                                                        marksEntryclass11.every((item) =>
                                                            marksTypeArr.includes(searchValues.markType.split('_').pop())
                                                            && item.is_final[`is_${searchValues.markType.toLowerCase().split('_').pop()}`]
                                                        ) ? "btn btn-success me-1" : "btn btn-secondary me-1"
                                                    }
                                                    onClick={() => handleFinalSubmit()}
                                                    disabled={
                                                        marksEntryclass11.every((item) =>
                                                            marksTypeArr.includes(searchValues.markType.split('_').pop())
                                                            && item.is_final[`is_${searchValues.markType.toLowerCase().split('_').pop()}`]
                                                        )
                                                    }
                                                >
                                                    {
                                                        marksEntryclass11.every((item) =>
                                                            marksTypeArr.includes(searchValues.markType.split('_').pop())
                                                            && item.is_final[`is_${searchValues.markType.toLowerCase().split('_').pop()}`]
                                                        ) ? "Submitted" : "Final Submit"
                                                    }
                                                </button>
                                            </ButtonToolbar>
                                        )
                                    }
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={marksEntryclass11}
                                            customStyles={customStyles}
                                            direction="auto"
                                            highlightOnHover
                                            persistTableHead
                                            progressPending={loadingIndicator}
                                            progressComponent={
                                                <CommonLoader
                                                    loadingIndicator={loadingIndicator}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-md-5">
                                            <div className="form-input-content text-center error-page">
                                                <h1 className="error-text fw-bold">Sorry</h1>
                                                <h4>
                                                    <i className="fa fa-thumbs-down text-danger" />
                                                    {marksEntry.message}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>

            {/* <!-- Modal --> */}
            <Modal
                backdrop='static'
                open={showOTPModal}
                onClose={() => setShowOTPModal(false)}
                overflow={false}
            >
                <Modal.Header>
                    <Modal.Title>{MARK_ENTRY_XI && "Examiner Details"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OTPModal
                        {...props}
                        fetchStudents={fetchStudents}
                        setShowOTPModal={setShowOTPModal}
                        otpUserInfo={otpUserInfo}
                        setOTPUserInfo={setOTPUserInfo}
                        modalType={"MARK_ENTRY_XI"}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default MarksEntryClassXI;
