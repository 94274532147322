import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Radio, RadioGroup, Form, Button } from 'rsuite';
import DataTable from 'react-data-table-component';
import DataTableSettings from '../../../helpers/DataTableSettings';
import PageTitle from '../../layouts/PageTitle';
import Select from 'react-select';
import CommonLoader from '../../components/loader/CommonLoader';

const disciplinesOptions = [
    { value: '', label: 'Select Discipline' },
    { value: '1', label: 'Discipline 1' },
    { value: '2', label: 'Discipline 2' },
    { value: '3', label: 'Discipline 3' }
];

const groupOptions = [
    { value: '', label: 'Select Group' },
    { value: '1', label: 'Group 1' },
    { value: '2', label: 'Group 2' },
    { value: '3', label: 'Group 3' }
];

const paperOptions = [
    { value: '', label: 'Select Paper' },
    { value: '1', label: 'Paper 1' },
    { value: '2', label: 'Paper 2' },
    { value: '3', label: 'Paper 3' }
];

const examYearOptions = [
    { value: '', label: 'Select Exam Year' },
    { value: '1', label: '2020' },
    { value: '2', label: '2021' },
    { value: '3', label: '2022' }
];

const sessionOptions = [
    { value: '', label: 'Select Session' },
    { value: '1', label: 'Session 1' },
    { value: '2', label: 'Session 2' },
    { value: '3', label: 'Session 3' }
];

const vtcOptions = [
    { value: '', label: 'Select VTC' },
    { value: '1', label: 'VTC 1' },
    { value: '2', label: 'VTC 2' },
    { value: '3', label: 'VTC 3' }
];

const initialValues = {
    examType: '',
    discipline: '',
    group: '',
    paper: '',
    examYear: '',
    sessionYear: '',
    vtc: ''
};

const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#6082b6',
            borderRight: '1px solid white',
            color: 'white',
        },
    },
};

const Class12Examination = (props) => {
    const [class12Data, setClass12Data] = useState([
        {
            id: 1,
            roll_number: 1,
            name: 'Student 1',
        },
        {
            id: 2,
            roll_number: 2,
            name: 'Student 2',
        },
        {
            id: 3,
            roll_number: 3,
            name: 'Student 3',
        },
        {
            id: 4,
            roll_number: 4,
            name: 'Student 4',
        },
        {
            id: 5,
            roll_number: 5,
            name: 'Student 5',
        },
    ]);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [searchValues, setSearchValues] = useState(initialValues);
    const [searchErrors, setSearchErrors] = useState({});

    const handleChange = (e, field) => {
        if (field === 'examType') {
            setSearchValues({ ...searchValues, examType: e });
        }
        if (field === 'discipline') {
            setSearchValues({ ...searchValues, discipline: e.value });
        }
        if (field === 'group') {
            setSearchValues({ ...searchValues, group: e.value });
        }
        if (field === 'paper') {
            setSearchValues({ ...searchValues, paper: e.value });
        }
        if (field === 'examYear') {
            setSearchValues({ ...searchValues, examYear: e.value });
        }
        if (field === 'sessionYear') {
            setSearchValues({ ...searchValues, sessionYear: e.value });
        }
        if (field === 'vtc') {
            setSearchValues({ ...searchValues, vtc: e.value });
        }
        setSearchErrors({ ...searchErrors, [field]: '' });
    };

    const handleStatusChange = (status) => {
        switch (status) {
            case 'ABSENT':

                break;

            case 'MARK_ABSENT':

                break;

            case 'EXPELLED':

                break;

            case 'MARK_EXPELLED':

                break;

            case 'RA':

                break;

            case 'MARK_RA':

                break;

            case 'COP':

                break;

            case 'MARK_COP':

                break;

            default:
                break;
        }
    };

    const validateSearch = () => {
        const {
            examType,
            discipline,
            group,
            paper,
            examYear,
            sessionYear,
            vtc
        } = searchValues;

        const errors = {};
        let isValid = true;

        if (!examType) {
            isValid = false;
            errors.examType = "Exam Type is Required";
        }
        if (!discipline) {
            isValid = false;
            errors.discipline = "Discipline is Required";
        }
        if (!group) {
            isValid = false;
            errors.group = "Group is Required";
        }
        if (!paper) {
            isValid = false;
            errors.paper = "Paper is Required";
        }
        if (!examYear) {
            isValid = false;
            errors.examYear = "Exam Year is Required";
        }
        if (!sessionYear) {
            isValid = false;
            errors.sessionYear = "Session Year is Required";
        }
        if (!vtc) {
            isValid = false;
            errors.vtc = "VTC is Required";
        }

        setSearchErrors(errors);
        return isValid;
    };

    const getAttendanceData = () => {
        if (!validateSearch()) {
            return;
        }
    };

    const columns = [
        {
            name: <h5 style={{ color: 'white' }}>Roll Number</h5>,
            selector: row => row.roll_number,
            sortable: true,
            reorder: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
        },
        {
            name: <h5 style={{ color: 'white' }}>Name</h5>,
            selector: row => row.name,
            sortable: true,
            reorder: true,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
        },
        {
            name: <h5 style={{ color: 'white' }}>Attendance</h5>,
            selector: row => row.attendance,
            sortable: false,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
            cell: (row) => (
                <div>
                    <Button appearance='primary' color='green' >Mark Absent</Button>
                </div>
            )
        },
        {
            name: <h5 style={{ color: 'white' }}>Expelled</h5>,
            selector: row => row.expelled,
            sortable: false,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
            cell: (row) => (
                <div>
                    <Button appearance='primary' color='green' >Mark Expelled</Button>
                </div>
            )
        },
        {
            name: <h5 style={{ color: 'white' }}>R.A</h5>,
            selector: row => row.ra,
            sortable: false,
            center: "true",
            style: { borderRight: '1px solid #6082b6' },
            cell: (row) => (
                <div>
                    <Button appearance='primary' color='green' >Mark R.A</Button>
                </div>
            )
        },
        {
            name: <h5 style={{ color: 'white' }}>C.O.P</h5>,
            selector: row => row.cop,
            sortable: false,
            center: "true",
            cell: (row) => (
                <div>
                    <Button appearance='primary' color='green' >Mark C.O.P</Button>
                </div>
            )
        },
    ];

    return (
        <>
            <PageTitle activeMenu={"Class XII Examination"} motherMenu={"Attendance"} />
            Class XII Examinations
            {/* <Row>
                <Col lg={12}>
                    <div className='card shadow'>
                        <div className="card-body" style={{ fontSize: "14px" }}>
                            <Form.Group controlId="radioList">
                                <label className="form-label" htmlFor="exam_type">Exam Type</label>
                                <RadioGroup name="radioList"
                                    inline
                                    className="d-flex justify-content-between"
                                    style={{ width: '35%' }}
                                    onChange={(e) => handleChange(e, 'examType')}
                                >
                                    <Radio value="Regular" checked={searchValues.examType === 'Regular'}>Regular</Radio>
                                    <Radio value="Continuing" checked={searchValues.examType === 'Continuing'}>Continuing</Radio>
                                    <Radio value="casual" checked={searchValues.examType === 'casual'}>Continuing/Casual</Radio>
                                </RadioGroup>
                                {
                                    searchErrors.examType && (
                                        <div className="text-danger fs-12">
                                            {searchErrors.examType}
                                        </div>
                                    )
                                }
                            </Form.Group>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="vtc">VTC</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={vtcOptions[searchValues.vtc]}
                                            options={vtcOptions}
                                            onChange={(e) => handleChange(e, 'vtc')}
                                        />
                                        {
                                            searchErrors.vtc && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.vtc}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="disciplines">Disciplines</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={disciplinesOptions[searchValues.discipline]}
                                            options={disciplinesOptions}
                                            onChange={(e) => handleChange(e, 'discipline')}
                                        />
                                        {
                                            searchErrors.discipline && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.discipline}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="groups">Groups</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={groupOptions[searchValues.group]}
                                            options={groupOptions}
                                            onChange={(e) => handleChange(e, 'group')}
                                        />
                                        {
                                            searchErrors.group && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.group}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="exam_year">Exam Year</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={examYearOptions[searchValues.examYear]}
                                            options={examYearOptions}
                                            onChange={(e) => handleChange(e, 'examYear')}
                                        />
                                        {
                                            searchErrors.examYear && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.examYear}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="session">Academic Session(Year)</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={sessionOptions[searchValues.sessionYear]}
                                            options={sessionOptions}
                                            onChange={(e) => handleChange(e, 'sessionYear')}
                                        />
                                        {
                                            searchErrors.sessionYear && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.sessionYear}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="paper">Paper</label>
                                        <Select
                                            isSearchable={false}
                                            defaultValue={paperOptions[searchValues.paper]}
                                            options={paperOptions}
                                            onChange={(e) => handleChange(e, 'paper')}
                                        />
                                        {
                                            searchErrors.paper && (
                                                <div className="text-danger fs-12">
                                                    {searchErrors.paper}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <button className="btn btn-primary me-1" onClick={() => getAttendanceData()}>
                                    Get Attendance Data
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            {
                class12Data && class12Data.length > 0 && (
                    <Row className='mb-4'>
                        <Col lg={12}>
                            <div className='w-100 p-3 rounded' style={{ backgroundColor: '#C1E1C1' }}>
                                <div className='w-75'>
                                    <div className='d-flex justify-content-between' style={{ color: '#097969', fontSize: '14px' }}>
                                        <div>
                                            <span>Total:{" 200"}</span>
                                        </div>
                                        <div>
                                            <span>Present:{" 100"}</span>
                                        </div>
                                        <div>
                                            <span>Absent:{" 20"}</span>
                                        </div>
                                        <div>
                                            <span>Expelled:{" 15"}</span>
                                        </div>
                                        <div>
                                            <span>R.A:{" 25"}</span>
                                        </div>
                                        <div>
                                            <span>Blocked:{" 10"}</span>
                                        </div>
                                        <div>
                                            <span>COP:{" 12"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )
            }

            <Row>
                <Col>
                    <div className="card shadow">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={class12Data && class12Data.length > 0 ? columns : []}
                                    data={class12Data}
                                    customStyles={customStyles}
                                    direction="auto"
                                    highlightOnHover
                                    pagination
                                    paginationPerPage={DataTableSettings.paginationPerPage}
                                    paginationRowsPerPageOptions={
                                        DataTableSettings.paginationRowsPerPageOptions
                                    }
                                    persistTableHead
                                    progressPending={loadingIndicator}
                                    progressComponent={
                                        <CommonLoader
                                            loadingIndicator={loadingIndicator}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}
        </>
    );
};

export default Class12Examination;
