import { Navigate } from 'react-router-dom';

/// Dashboard
import Home from "../../pages/dashboard/Home";

//Profile
import EditProfile from "../../pages/profile/EditProfile";

//Student
import ListOfStudent from '../../pages/students/ListOfStudent';
import AddStudent from '../../pages/students/AddStudent';
import EditStudent from '../../pages/students/EditStudent';
import StudentProfile from "../../pages/students/StudentProfile";

//Attendance
import Class11Examination from '../../pages/attendance/Class11Examination';
import Class12Examination from '../../pages/attendance/Class12Examination';

//Marks Entry
import MarksEntryClassXI from '../../pages/marksEntry/MarksEntryClassXI';
import MarksEntryClassXII from '../../pages/marksEntry/MarksEntryClassXII';
import ApprovalClassXI from '../../pages/marksEntry/ApprovalClassXI';
import MarksEntrySchedule from '../../pages/marksEntry/MarksEntrySchedule';

//User
import AllUser from '../../pages/users/AllUser';
import AddUser from '../../pages/users/AddUser';
import EditUser from '../../pages/users/EditUser';
import UserProfile from "../../pages/users/UserProfile";

//Notifications
import AllNotifications from "../../pages/notification/AllNotifications";
import AddNotification from "../../pages/notification/AddNotification";
import EditNotification from "../../pages/notification/EditNotification";
import ViewNotification from "../../pages/notification/ViewNotification";

//Master List
import NodalList from "../../pages/masterData/nodals/NodalList";
import StateList from "../../pages/masterData/states/StateList";
import DistrictList from "../../pages/masterData/districts/DistrictList";
import SubdivisionList from "../../pages/masterData/subdivisions/SubdivisionList";
import PoliceStationList from "../../pages/masterData/policeStations/PoliceStationList";
import CasteList from "../../pages/masterData/castes/CasteList";
import DisciplineList from "../../pages/masterData/disciplines/DisciplineList";
import EducationBoardLlist from "../../pages/masterData/boards/EducationBoardLlist";
import ExamTypeList from "../../pages/masterData/examTypes/ExamTypeList";
import NationalityList from "../../pages/masterData/nationality/NationalityList";
import GenderList from "../../pages/masterData/genders/GenderList";
import RelationshipList from "../../pages/masterData/relationships/RelationshipList";
import ReligionList from "../../pages/masterData/religions/ReligionList";
import BlockMunicipalityList from "../../pages/masterData/blockMunicipality/BlockMunicipalityList";
import VTCList from "../../pages/masterData/vtc/VTCList";

const AllRoutes = (props) => {
    const userRole = localStorage.getItem("userRole");

    return [
        {
            url: "dashboard",
            component: <Home {...props} />
        },
        {
            url: "profile",
            component: <EditProfile {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "nodal-list",
            component: <NodalList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "state-list",
            component: <StateList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "district-list",
            component: <DistrictList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "subdivision-list",
            component: <SubdivisionList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "police-station-list",
            component: <PoliceStationList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "caste-list",
            component: <CasteList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "discipline-list",
            component: <DisciplineList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "education-board-list",
            component: <EducationBoardLlist {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "exam-type-list",
            component: <ExamTypeList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "nationality-list",
            component: <NationalityList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "gender-list",
            component: <GenderList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "relationship-list",
            component: <RelationshipList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "religion-list",
            component: <ReligionList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "block-municipality-list",
            component: <BlockMunicipalityList {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "vtc-list",
            component: <VTCList {...props} />
        },
        // {
        //     url: "list-of-students",
        //     component: <ListOfStudent {...props} />
        // },
        {
            url: "add-student",
            component: <AddStudent {...props} />
        },
        {
            url: "edit-student/:studId",
            component: <EditStudent {...props} />
        },
        {
            url: "student-profile/:studId",
            component: <StudentProfile {...props} />
        },
        {
            url: "class-xi-attendence",
            component: <Class11Examination {...props} />
        },
        {
            url: "class-xii-examination",
            component: <Class12Examination {...props} />
        },
        {
            url: "class-xi-marks-entry",
            component: <MarksEntryClassXI {...props} />
        },
        {
            url: "marks-entry-class-xii",
            component: <MarksEntryClassXII {...props} />
        },
        {
            url: "class-xi-marks-entry-hoi-approval",
            component: <ApprovalClassXI {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "marks-entry-schedule",
            component: <MarksEntrySchedule {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "all-users",
            component: <AllUser {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "add-user",
            component: <AddUser {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "edit-user/:userId",
            component: <EditUser {...props} />
        },
        ['SUPERADMIN'].includes(userRole) && {
            url: "user-profile/:userId",
            component: <UserProfile {...props} />
        },
        {
            url: "all-notification",
            component: <AllNotifications {...props} />
        },
        {
            url: "add-notification",
            component: <AddNotification {...props} />
        },
        {
            url: "edit-notification/:notificationId",
            component: <EditNotification {...props} />
        },
        {
            url: "view-notification/:notificationId",
            component: <ViewNotification {...props} />
        },
        {
            url: "/",
            component: <Navigate to="/dashboard" replace />
        },
    ];
};

export default AllRoutes;