import React from "react";

const Footer = (props) => {
  const d = new Date();

  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a href="https://www.aranaxweb.com/" target="_blank" rel="noreferrer">
            Aranax Technologies Pvt. Ltd.
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
