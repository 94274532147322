import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Form, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import DataTableSettings from "../../../helpers/DataTableSettings";

import PageTitle from '../../layouts/PageTitle';
import CommonLoader from '../../components/loader/CommonLoader';

const data = [
    { id: 1, title: 'Garrett Winters', type: 'Topbar', publishedOn: '2020/07/25', expiredOn: '2020/07/28', isActive: 'Active' },
    { id: 2, title: 'Airi Satou', type: 'Modal', publishedOn: '2021/08/25', expiredOn: '2021/08/28', isActive: 'Inactive' },
    { id: 3, title: 'Tiger Nixon', type: 'Maintenance', publishedOn: '2022/09/25', expiredOn: '2022/09/28', isActive: 'Active' },
    { id: 4, title: 'Cedric Kelly', type: 'Modal', publishedOn: '2023/10/25', expiredOn: '2023/10/28', isActive: 'Inactive' },
    { id: 5, title: 'Garrett Winters', type: 'Topbar', publishedOn: '2019/07/25', expiredOn: '2019/07/28', isActive: 'Active' },
    { id: 6, title: 'Airi Satou', type: 'Maintenance', publishedOn: '2018/08/25', expiredOn: '2018/08/28', isActive: 'Inactive' },
    { id: 7, title: 'Tiger Nixon', type: 'Topbar', publishedOn: '2017/09/25', expiredOn: '2017/09/28', isActive: 'Active' },
    { id: 8, title: 'Cedric Kelly', type: 'Modal', publishedOn: '2016/10/25', expiredOn: '2016/10/28', isActive: 'Inactive' },
    { id: 9, title: 'Garrett Winters', type: 'Maintenance', publishedOn: '2020/07/25', expiredOn: '2020/07/28', isActive: 'Active' },
    { id: 10, title: 'Airi Satou', type: 'Modal', publishedOn: '2021/08/25', expiredOn: '2021/08/28', isActive: 'Inactive' },
    { id: 11, title: 'Tiger Nixon', type: 'Topbar', publishedOn: '2022/09/25', expiredOn: '2022/09/28', isActive: 'Active' },
    { id: 12, title: 'Cedric Kelly', type: 'Maintenance', publishedOn: '2023/10/25', expiredOn: '2023/10/28', isActive: 'Inactive' },
    { id: 13, title: 'Garrett Winters', type: 'Topbar', publishedOn: '2019/07/25', expiredOn: '2019/07/28', isActive: 'Active' },
    { id: 14, title: 'Airi Satou', type: 'Modal', publishedOn: '2018/08/25', expiredOn: '2018/08/28', isActive: 'Inactive' },
    { id: 15, title: 'Tiger Nixon', type: 'Maintenance', publishedOn: '2017/09/25', expiredOn: '2017/09/28', isActive: 'Active' },
    { id: 16, title: 'Cedric Kelly', type: 'Modal', publishedOn: '2016/10/25', expiredOn: '2016/10/28', isActive: 'Inactive' },
    { id: 17, title: 'Gavin Joyce', type: 'Topbar', publishedOn: '2019/07/25', expiredOn: '2019/07/28', isActive: 'Active' },
    { id: 18, title: 'Angelica Ramos', type: 'Maintenance', publishedOn: '2018/08/25', expiredOn: '2018/08/28', isActive: 'Inactive' },
    { id: 19, title: 'Paul Byrd', type: 'Topbar', publishedOn: '2017/09/25', expiredOn: '2017/09/28', isActive: 'Active' },
    { id: 20, title: 'Ashton Cox', type: 'Modal', publishedOn: '2016/10/25', expiredOn: '2016/10/28', isActive: 'Inactive' },
];

const AllNotifications = (props) => {
    const [notifications, setNotifications] = useState(data);
    const [loadingIndicator, setLoadingIndicator] = useState(true);
    const [page] = useState(1);
    const [newPerPage, setNewPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [filterText, setFilterText] = useState("");
    const searchParam = ["title", "type", "publishedOn", "expiredOn", "isActive"];

    useEffect(() => {
        setTimeout(() => {
            setLoadingIndicator(false);
        }, 2000);
    }, []);

    const columns = [
        {
            name: <h5>Message</h5>,
            selector: row => row.title,
            sortable: true,
        },
        {
            name: <h5>Display Position</h5>,
            selector: row => row.type,
            sortable: true,
        },
        {
            name: <h5>Start Date</h5>,
            selector: row => row.publishedOn,
            sortable: true,
            cell: (row) => props.getFormatedDate(row.publishedOn),
        },
        {
            name: <h5>Start End</h5>,
            selector: row => row.expiredOn,
            sortable: true,
            cell: (row) => props.getFormatedDate(row.expiredOn),
        },
        {
            name: <h5>Status</h5>,
            selector: row => row.isActive,
            sortable: true,
        },
        {
            name: <h5>Action</h5>,
            selector: row => row.action,
            sortable: false,
            cell: (row) => (
                <div>
                    <Link to={"#"} className="btn btn-xs sharp btn-primary me-1"><i className="fa fa-pencil" /></Link>
                    <Link to={"#"} className="btn btn-xs sharp btn-danger"><i className="fa fa-trash" /></Link>
                </div>
            )
        },
    ];

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Form className="d-flex">
                            <Form.Control
                                type="search"
                                placeholder="Search..."
                                className="me-2 rounded-pill"
                                aria-label="Search"
                                onChange={(e) => setFilterText(e.target.value)}
                            />
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }, []);

    return (
        <>
            <PageTitle activeMenu={"All Notification"} motherMenu={"Notification"} />
            <Row>
                <Tab.Container defaultActiveKey={"List"}>
                    <div className="col-lg-12">
                        <Tab.Content className="row tab-content">
                            <Tab.Pane eventKey="List" className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">All Notifications List</h4>
                                        <Link to={"/add-notification"} className="btn btn-primary">+ Add New</Link>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={notifications.length > 0 ? columns : []}
                                                data={DataTableSettings.filterItems(
                                                    notifications,
                                                    searchParam,
                                                    filterText
                                                )}
                                                pagination
                                                paginationPerPage={DataTableSettings.paginationPerPage}
                                                paginationRowsPerPageOptions={
                                                    DataTableSettings.paginationRowsPerPageOptions
                                                }
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                                persistTableHead
                                                progressPending={loadingIndicator}
                                                progressComponent={
                                                    <CommonLoader
                                                        loadingIndicator={loadingIndicator}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </Row>
        </>
    );
};

export default AllNotifications;
