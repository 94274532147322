import React from 'react';

const SiteMaintenance = ({ message }) => {
    const messageText = message ? message : `We are currently performing maintenance on our website. Please check back later.`;

    return (
        <div className="maintenance-container">
            <img src="/assets/images/maintenance.png" alt='site maintenance' />
            <h4 style={{ width: '70%' }} className="mt-3 fs-3">{messageText}</h4>
        </div>
    );
};

export default SiteMaintenance;