const MenuList = (props) => {
    const userRole = localStorage.getItem("userRole");

    const menuItems = [
        {
            title: 'Dashboard',
            menuTitle: 'dashboard',
            to: 'dashboard',
            iconStyle: <i className="la la-home" />,
        },
        ['SUPERADMIN'].includes(userRole) && {
            title: 'Master Lists',
            menuTitle: 'masterLists',
            classsChange: 'mm-collapse',
            iconStyle: <i className="las la-list" />,
            content: [
                {
                    title: 'Nationality Master',
                    menuTitle: 'nationalityMaster',
                    to: 'nationality-list',
                },
                {
                    title: 'State Master',
                    menuTitle: 'stateMaster',
                    to: 'state-list',
                },
                {
                    title: 'District Master',
                    menuTitle: 'districtMaster',
                    to: 'district-list',
                },
                {
                    title: 'Sub-division Master',
                    menuTitle: 'subDivisionMaster',
                    to: 'subdivision-list',
                },
                {
                    title: 'Block Municipality Master',
                    menuTitle: 'blockMunicipalityMaster',
                    to: 'block-municipality-list',
                },
                {
                    title: 'Police Station Master',
                    menuTitle: 'nationalityMaster',
                    to: 'police-station-list',
                },
                {
                    title: 'Nodal Master',
                    menuTitle: 'nodalMaster',
                    to: 'nodal-list',
                },
                {
                    title: 'Education Board Master',
                    menuTitle: 'educationBoardMaster',
                    to: 'education-board-list',
                },
                {
                    title: 'Exam Type Master',
                    menuTitle: 'examTypeMaster',
                    to: 'exam-type-list',
                },
                {
                    title: 'Discipline Master',
                    menuTitle: 'disciplineMaster',
                    to: 'discipline-list',
                },
                {
                    title: 'Religion Master',
                    menuTitle: 'religionMaster',
                    to: 'religion-list',
                },
                {
                    title: 'Caste Master',
                    menuTitle: 'casteMaster',
                    to: 'caste-list',
                },
                {
                    title: 'Gender Master',
                    menuTitle: 'genderMaster',
                    to: 'gender-list',
                },
                {
                    title: 'Relationship Master',
                    menuTitle: 'relationshipMaster',
                    to: 'relationship-list',
                },
                {
                    title: 'VTC Master',
                    menuTitle: 'vtcMaster',
                    to: 'vtc-list',
                },
                {
                    title: 'Marks Entry Schedule',
                    menuTitle: 'marksEntrySchedule',
                    to: 'marks-entry-schedule',
                },
            ],
        },
        // ['SUPERADMIN'].includes(userRole) && {
        //     title: 'Students',
        //     menuTitle: 'students',
        //     classsChange: 'mm-collapse',
        //     iconStyle: <i className="la la-users" />,
        //     content: [
        //         {
        //             title: 'List Of Students',
        //             menuTitle: 'listOfStudents',
        //             to: 'list-of-students',
        //         },
        //     ],
        // },
        {
            title: 'Class XI',
            menuTitle: 'classXI',
            classsChange: 'mm-collapse',
            iconStyle: <i className="la la-calendar-check-o" />,
            content: [
                {
                    title: 'Attendence',
                    menuTitle: 'attendenceClassXI',
                    to: 'class-xi-attendence',
                },
                {
                    title: 'Marks Entry',
                    menuTitle: 'marksEntryClassXI',
                    to: 'class-xi-marks-entry',
                },
                {
                    title: 'Marks Entry HOI Approval',
                    menuTitle: 'marksEntryHOIApproval',
                    to: 'class-xi-marks-entry-hoi-approval',
                },
            ],
        },
        ['SUPERADMIN'].includes(userRole) && {
            title: 'Users',
            menuTitle: 'users',
            classsChange: 'mm-collapse',
            iconStyle: <i className="la la-users" />,
            content: [
                {
                    title: 'All Users',
                    menuTitle: 'allUsers',
                    to: 'all-users',
                },
            ],
        },
    ];

    const filteredMenuItems = menuItems.map(item => ({
        ...item,
        content: item.content ? item.content.filter(subItem => subItem !== false) : [],
    })).filter(item => item !== false && (item.menuTitle === 'dashboard' || (item.content !== undefined && item.content.length > 0)));

    return userRole && filteredMenuItems;
};

export default MenuList;
