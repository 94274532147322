import React from 'react';

const VTCHome = (props) => {

    return (
        <div className="fix-wrapper">
            <div className="container">
                {" "}
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="form-input-content text-center">
                            <h1 className="fw-bold">Welcome To Dashboard</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VTCHome;
