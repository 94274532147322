import { Suspense } from 'react';

// Style
import 'rsuite/dist/rsuite-no-reset.min.css';
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";

//Helper and Hooks
import Common from './helpers/common';
import useAuth from "./hooks/useAuth";

//Router
import AuthRoutes from './jsx/router/auth/AuthRoutes';
import Index from './jsx/router/main/index';

//Components and Pages
import SiteMaintenance from './jsx/pages/siteMaintenance/SiteMaintenance';
import PreLoader from './jsx/components/loader/PreLoader';

function App(props) {
    const { isAuthenticated } = useAuth();
    const { under_maintenance, message } = props;
    const isAdmin = localStorage.getItem('is_admin');

    if (under_maintenance && Number(isAdmin) !== 1) return <SiteMaintenance message={message} />;

    if (isAuthenticated) {
        return (
            <>
                <Suspense fallback={<PreLoader />}>
                    <Index {...props} />
                </Suspense>
            </>
        );
    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={<PreLoader />}>
                    <AuthRoutes {...props} />
                </Suspense>
            </div>
        );
    }
};

export default Common(App); 